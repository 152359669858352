import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface HeadCell {
  id: string;
  disablePadding: boolean;
}

const headCells: HeadCell[] = [
  { id: 'Reservation ID(s)', disablePadding: true },
  { id: 'Type of Misconduct', disablePadding: false },
  { id: 'Degree of Misconduct', disablePadding: false },
  { id: 'First Name', disablePadding: false },
  { id: 'Last Name', disablePadding: false },
  { id: 'Phone No.', disablePadding: false },
  { id: 'Email Address', disablePadding: false },
  { id: 'Street Name & Number', disablePadding: false },
  { id: 'Postal Code', disablePadding: false },
  { id: 'City', disablePadding: false },
  { id: 'Country', disablePadding: false },
];

interface WatchListTableHeaderProps {
  selections: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

export const WatchListTableHeader: React.FC<WatchListTableHeaderProps> = ({
  onSelectAllClick,
  selections,
  rowCount,
}) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={selections > 0 && selections < rowCount}
            checked={rowCount > 0 && selections === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': t('general.selectAll') }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} align="center" padding={headCell.disablePadding ? 'none' : 'default'}>
            {headCell.id}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
