import React from 'react';

import { ReservationDetails } from '../../types';
import { SimpleCard } from '../common';

type Props = Pick<ReservationDetails, 'reservation'>;

function RegistrationStatus({ reservation }: Props) {
  if (!reservation.onlineCheckinStatus || !reservation.onlineCheckinStatus.message.length) {
    return <SimpleCard title="Registration Status">Reservation has not started registration</SimpleCard>;
  }

  return (
    <SimpleCard title="Registration Status">
      {reservation.onlineCheckinStatus.checkedIn
        ? 'Online check-in completed'
        : reservation.onlineCheckinStatus.message}
    </SimpleCard>
  );
}

export default RegistrationStatus;
