import React from 'react';

import { useStyles } from './styles';
import { S3FileProps } from './types';

export const S3Image = ({ downloadUrl, isPreview }: S3FileProps) => {
  const classes = useStyles();
  if (isPreview) {
    return (
      <div className={classes.thumbnail}>
        <img src={downloadUrl} className={classes.image} />
      </div>
    );
  }

  return (
    <div className={classes.imageWrapper}>
      <img src={downloadUrl} />
    </div>
  );
};
