import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';

import { SectionField, SelectField, TextInputField } from '../common/section';
import {
  BulkMessageEmailTypes,
  BulkMessageEmailTypesEnum,
  BulkMessageFilters,
  EnumFilterTypes,
  FILTER_TYPES,
  MessageTypes,
  RESERVATION_STATUS_OPTIONS,
} from './types';

interface BulkMessageFilterProps {
  messageType: MessageTypes;
  filters: BulkMessageFilters;
  onChange: (filters: BulkMessageFilters) => void;
}

const RESERVATION_ID_SPLIT_REGEXP = /,| |; /; // regexp to split a comma or semicolon separated string

const BulkMessageFilter: React.FC<BulkMessageFilterProps> = ({ messageType, filters, onChange }) => {
  const { t } = useTranslation();

  const [reservationStatus, setReservationStatus] = useState<string>(filters.reservationStatus);

  const [reservationIds, setReservationIds] = useState<string>(filters?.reservationIds ?? '');

  const [filterType, setFilterType] = useState<string | undefined>(EnumFilterTypes.RESERVATION_STATUS);

  const [reservationDate, setReservationDate] = useState<Date>(
    filters.reservationDate ? new Date(filters.reservationDate) : new Date()
  );

  const [emailType, setEmailType] = React.useState<BulkMessageEmailTypesEnum>(
    filters?.emailType ?? BulkMessageEmailTypesEnum.individualEmail
  );

  const handleOnchange = (key: keyof BulkMessageFilters, value: any) => {
    const newFilters = { ...filters };
    newFilters[key] = value;

    onChange(newFilters);
  };

  return (
    <div>
      {messageType === MessageTypes.EMAIL && (
        <div>
          <SelectField
            field="emailType"
            label={t('bulkMessaging.emailType')}
            inputLabel={t('bulkMessaging.emailType')}
            onChange={(_field, value) => {
              Object.keys(BulkMessageEmailTypes).forEach(el => {
                if (value === BulkMessageEmailTypes[el as BulkMessageEmailTypesEnum]) {
                  setEmailType(el as BulkMessageEmailTypesEnum);
                  handleOnchange('emailType', el);
                }
              });
            }}
            value={BulkMessageEmailTypes[emailType]}
            options={Object.values(BulkMessageEmailTypes).map(value => ({ value, label: value }))}
            align="flex-end"
          />
        </div>
      )}
      <SelectField
        field="filterType"
        label={t('bulkMessaging.filterType')}
        onChange={(_field, value) => {
          setFilterType(value);
        }}
        value={filterType}
        options={FILTER_TYPES.map(value => ({ value, label: value }))}
      />

      {filterType === EnumFilterTypes.RESERVATION_ID && (
        <SectionField label={t('bulkMessaging.reservationIds')}>
          <TextInputField
            helperText={t('bulkMessaging.reservationIdsHelper')}
            field="reservationIds"
            onChange={(_, value) => {
              handleOnchange(
                'reservationIds',
                value.split(RESERVATION_ID_SPLIT_REGEXP).map(el => el.trim())
              );
              setReservationIds(value);
            }}
            value={reservationIds}
          />
        </SectionField>
      )}

      {filterType === EnumFilterTypes.RESERVATION_STATUS && (
        <>
          <SelectField
            field="reservationStatus"
            label={t('bulkMessaging.reservationStatus')}
            onChange={(_field, value) => {
              setReservationStatus(value);
              handleOnchange('reservationStatus', value);
            }}
            value={reservationStatus}
            options={RESERVATION_STATUS_OPTIONS.map(value => ({ value, label: value }))}
            inputLabel={t('bulkMessaging.reservationStatusLabel').toLowerCase()}
          />

          <DatePicker
            label={t('bulkMessaging.reservationDate')}
            name="start"
            value={reservationDate}
            onChange={e => {
              if (e) {
                setReservationDate(e);
                handleOnchange('reservationDate', e.toISOString());
              }
            }}
            size="small"
          />
        </>
      )}
    </div>
  );
};

export default BulkMessageFilter;
