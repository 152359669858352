import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, GridItemsAlignment, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import SectionField from './SectionField';

interface SelectFieldProps {
  onChange: (x: any, y: string) => void;
  label: string;
  field?: string;
  value?: string;
  options: { value: string; label: string }[];
  inputLabel?: string;
  align?: GridItemsAlignment;
  disabled?: boolean;
}

const useStyles = makeStyles({
  form: {
    marginLeft: 'auto',
    minWidth: 80,
  },
});

const SelectField: React.FC<SelectFieldProps> = ({
  onChange,
  label,
  field,
  value,
  options,
  align,
  inputLabel,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onChange(field || label, event.target.value as string);
  };

  return (
    <SectionField label={label} align={align}>
      <FormControl className={classes.form}>
        <InputLabel>{inputLabel || t('general.selection')}</InputLabel>
        <Select value={value || ''} onChange={handleChange} disabled={disabled}>
          {options.map(option => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </SectionField>
  );
};

export default SelectField;
