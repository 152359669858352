import React from 'react';
import { useQuery } from 'react-query';
import { CircularProgress } from '@material-ui/core';

import { S3Image } from './S3Image';
import { S3Pdf } from './S3Pdf';
import { FileInfo, S3FileProps } from './types';

const S3File = (props: S3FileProps) => {
  const { downloadUrl, onClick } = props;
  const { isLoading, isError, data } = useQuery<unknown, unknown, FileInfo>(
    ['file-info', downloadUrl],
    async () => {
      const response = await fetch(downloadUrl, {
        method: 'get',
      });

      if (!response.ok) {
        throw new Error('Could not fetch file');
      }

      const contentType = response.headers.get('Content-Type');

      if (contentType?.startsWith('image')) {
        return {
          type: 'image',
        };
      }

      return {
        type: 'pdf',
      };
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <>Error, refresh the page</>;
  }

  if (data?.type === 'image') {
    return (
      <div onClick={onClick}>
        <S3Image {...props} />
      </div>
    );
  }

  return (
    <div onClick={onClick}>
      <S3Pdf {...props} />
    </div>
  );
};
export default S3File;
