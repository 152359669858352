import React from 'react';

import { PageContainer } from '../components/common';
import { WatchListHeader, WatchListTable } from '../components/watchList';

const WatchList: React.FC = () => {
  return (
    <PageContainer>
      <WatchListHeader />
      <WatchListTable />
    </PageContainer>
  );
};

export default WatchList;
