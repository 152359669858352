import React from 'react';
import { makeStyles, Switch } from '@material-ui/core';

import SectionField from './SectionField';

interface SwitchFieldProps {
  onChange: (x: any, y: boolean) => void;
  label: string;
  complement?: string;
  field?: string;
  value?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles({
  switch: {
    marginLeft: 'auto',
  },
});

const SwitchField: React.FC<SwitchFieldProps> = ({ onChange, label, complement, field, value, disabled }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(field || label, event.target.checked);
  };

  return (
    <SectionField label={label} complement={complement}>
      <Switch color="primary" checked={value} onChange={handleChange} className={classes.switch} disabled={disabled} />
    </SectionField>
  );
};

export default SwitchField;
