import { Guest } from "../../../types";

export const getGuestDocumentStatus = (guest: Guest): string => {
    const { documents, reviewRequired } = guest;
    if (!reviewRequired) {
        return 'No review required';
    }
    const guestDocument = documents.length ? documents[0] : undefined;

    if (!guestDocument || guestDocument.isValid === undefined) {
        return 'Nothing submitted yet';
    }
    if (guestDocument?.documentSource === 'OCR') {
        return guestDocument?.isValid ? 'Approved (by Incode)' : 'Manual review pending';
    }
    if (guestDocument?.documentSource === 'manual') {
        return guestDocument?.isValid ? 'Approved (manually)' : 'Rejected';
    }
    return '';
}
