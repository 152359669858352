import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { ErrorMessage, Loading, PageContainer } from '../components/common';
import ReservationSection from '../components/reservationDetails/ReservationSection';
import { HskDelayResponse, QueryStrings } from '../types';
import { fetchReservationDetails, getHskDelay, useQueryApiData } from '../util/api';

const ReservationDetailsPage: React.FC = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const resId = params.get('reservationId') || '';
  const { data, isError, isLoading, refetch } = useQueryApiData(
    [resId, QueryStrings.RESERVATION_DETAILS],
    fetchReservationDetails,
    {
      refetchInterval: 10000,
    }
  );
  const { data: hskDelay } = useQueryApiData<HskDelayResponse>(
    [resId, QueryStrings.HSK_DELAY],
    getHskDelay,
    {
      refetchInterval: 30000,
    }
  );
  const { t } = useTranslation();

  return (
    <PageContainer>
      {isLoading && <Loading />}

      {isError && <ErrorMessage message={t('general.error')} showLogout={true} />}

      {!isLoading && !isError && !data?.reservation && (
        <Typography variant="subtitle1">{t('general.noData')}</Typography>
      )}

      {!isError && data && (
        <ReservationSection {...data} refetchReservation={refetch} hskDelay={hskDelay} />
      )}
    </PageContainer>
  );
};

export default ReservationDetailsPage;
