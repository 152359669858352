import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { EnumRecordArchivedState, QueryStrings, SelectedMisconductRecords, SuspiciousGuestRecord, SuspiciousGuestRecordPaginationFilter, SuspiciousGuestRecordPaginationResult } from '../../types';
import {
  archiveMisconductRecords,
  fetchSuspiciousGuestList,
  unarchiveMisconductRecords,
  useMutateApiData,
  useQueryApiData,
} from '../../util/api';
import { ErrorMessage, Loading } from '../common';
import { WatchListRecords } from './WatchListRecords';
import { WatchListToolBar } from './WatchListToolBar';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '50px',
    },
    archivedList: {
      marginTop: '50px',
    },
  })
);

const ONE_DAY_IN_MILLISECONDS = 86400000; // Use this to technically disable refresh

const DEFAULT_PAGINATION_FILTER: SuspiciousGuestRecordPaginationFilter = {
  limit: undefined,
  offsetId: undefined,
  offsetPropertyId: undefined,
};


export const WatchListTable: React.FC = () => {
  const { t } = useTranslation();

  const [misconductRecords, setMisconductRecords] = useState<SuspiciousGuestRecord[]>([]);
  const [paginationFilter, setPaginationFilter] = useState<SuspiciousGuestRecordPaginationFilter>(DEFAULT_PAGINATION_FILTER);

  const [firstPageLoading, setFirstPageLoading] = useState<boolean>(true);
  const { data, isError } = useQueryApiData<SuspiciousGuestRecordPaginationResult>(
    [QueryStrings.SUSPICIOUS_GUESTS, paginationFilter.offsetId || ''],
    () => fetchSuspiciousGuestList(paginationFilter),
    {
      refetchInterval: ONE_DAY_IN_MILLISECONDS,
    }
  );

  const classes = useStyles();
  const {
    isLoading: archiveRequestLoading,
    error: archiveRequestError,
    mutateAsync: recordsArchiveRequest,
  } = useMutateApiData(archiveMisconductRecords);
  const {
    isLoading: unarchiveRequestLoading,
    error: unarchiveRequestError,
    mutateAsync: recordsUnarchiveRequest,
  } = useMutateApiData(unarchiveMisconductRecords);

  const [selectedMisconductRecords, setSelectedMisconductRecords] = useState<SelectedMisconductRecords>({
    archived: [],
    active: [],
  });
  const [deactivate, setDeactivate] = useState<boolean>(false);
  const [activate, setActivate] = useState<boolean>(false);

  useEffect(() => {
    setMisconductRecords([...misconductRecords, ...(data?.suspiciousGuestRecords || [])]);
    if (data?.suspiciousGuestRecords?.length) {
      setFirstPageLoading(false);
    }

    if (data?.paginationFilter?.offsetId) {
      setPaginationFilter(data.paginationFilter);
    }
  }, [data]);

  if (firstPageLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorMessage message={t('general.error')} />;
  }

  if (!misconductRecords?.length) {
    return <Typography variant="subtitle1">{t('general.noData')}</Typography>;
  }

  const activeWatchListRecords = misconductRecords.filter(record => !record.archived);
  const archivedWatchListRecords = misconductRecords.filter(record => record.archived);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>, field: keyof SelectedMisconductRecords) => {
    if (event.target.checked) {
      const records = field === EnumRecordArchivedState.Active ? activeWatchListRecords : archivedWatchListRecords;
      const selections = records.map(record => record.recordId);
      setSelectedMisconductRecords({
        ...selectedMisconductRecords,
        [field]: selections,
      });
      return;
    }
    setSelectedMisconductRecords({
      ...selectedMisconductRecords,
      [field]: [],
    });
  };

  const handleCheckboxClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    recordId: string,
    field: keyof SelectedMisconductRecords
  ) => {
    let records = selectedMisconductRecords[field];

    if (records.includes(recordId)) {
      records = records.filter(record => record !== recordId);
    } else {
      records.push(recordId);
    }

    setSelectedMisconductRecords({
      ...selectedMisconductRecords,
      [field]: records,
    });
  };

  const onArchiveStateChange = async (_: any, field: keyof SelectedMisconductRecords): Promise<void> => {
    switch (field) {
      case EnumRecordArchivedState.Active: {
        const deactivateToggle = !deactivate;
        setDeactivate(deactivateToggle);

        const shouldDeactivate = deactivateToggle === true;
        if (shouldDeactivate && selectedMisconductRecords.active.length) {
          await recordsArchiveRequest([selectedMisconductRecords.active]);
          setDeactivate(false);
        }
        break;
      }
      case EnumRecordArchivedState.Archived: {
        const activateToggle = !activate;
        setActivate(activateToggle);

        const shouldActivate = activateToggle === true;
        if (shouldActivate && selectedMisconductRecords.archived.length) {
          await recordsUnarchiveRequest([selectedMisconductRecords.archived]);
          setActivate(false);
        }
        break;
      }
      default:
        break;
    }

    setSelectedMisconductRecords({
      ...selectedMisconductRecords,
      [field]: [],
    });

    const manuallyUpdatedMisconductRecords = misconductRecords.map(record => {
      if (selectedMisconductRecords[field].includes(record.recordId)) {
        return {
          ...record,
          archived: field === EnumRecordArchivedState.Active ? true : false,
        };
      }
      return record;
    });
    setMisconductRecords(manuallyUpdatedMisconductRecords);
  };

  return (
    <div className={classes.root}>

      {archivedWatchListRecords.length ? (
        <div className={classes.archivedList}>
          <h2>{t('guestWatchList.archivedList')}</h2>

          <WatchListToolBar
            onChange={event => onArchiveStateChange(event, EnumRecordArchivedState.Archived)}
            checked={activate}
            numOfSelections={selectedMisconductRecords.archived.length}
            actionLabel={t('sections.activate')}
            loading={unarchiveRequestLoading}
            error={!!unarchiveRequestError}
          />
          <WatchListRecords
            records={archivedWatchListRecords}
            handleSelectAllClick={handleSelectAllClick}
            handleCheckboxClick={handleCheckboxClick}
            selectedRecords={selectedMisconductRecords.archived}
            recordType={EnumRecordArchivedState.Archived}
          />
        </div>
      ) : null}

      {activeWatchListRecords.length > 0 ? (
        <div>
          <h2>{t('guestWatchList.active')}</h2>

          <WatchListToolBar
            onChange={event => onArchiveStateChange(event, EnumRecordArchivedState.Active)}
            checked={deactivate}
            numOfSelections={selectedMisconductRecords.active.length}
            actionLabel={t('sections.deactivate')}
            loading={archiveRequestLoading}
            error={!!archiveRequestError}
          />
          <WatchListRecords
            records={activeWatchListRecords}
            handleSelectAllClick={handleSelectAllClick}
            handleCheckboxClick={handleCheckboxClick}
            selectedRecords={selectedMisconductRecords.active}
            recordType={EnumRecordArchivedState.Active}
          />
        </div>
      ) : null}
    </div>
  );
};
