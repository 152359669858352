export enum MisconductDegree {
  LOW = 'low',
  HIGH = 'high',
  NONE = 'none',
}

export interface PinCodes {
  pinComfortCode?: string;
  backupCode?: string;
  comfortLink?: string;
}

export interface Document {
  url: string;
  name: string;
  type: string;
  key: string;
}

export enum EnumReservationStatus {
  'Confirmed' = 'Confirmed',
  'InHouse' = 'InHouse',
  'CheckedOut' = 'CheckedOut',
  'Canceled' = 'Canceled',
  'NoShow' = 'NoShow',
}

export enum EnumCovDocumentStatus {
  'APPROVED' = 'approved',
  'REJECTED' = 'rejected',
  'NEEDS_CHECKING' = 'needs_checking',
}

export interface Guest {
  id: string;
  firstName: string;
  lastName: string;
  downloadUrls: {
    covidDocuments: Document[];
    idDocuments?: Document[];
  };
}

export interface ReservationDetails {
  reservation: {
    id: string;
    bookingId: string;
    invoiceRecipients?: string[];
    bookingConfirmationSent: boolean;
    breakfastConfirmationSent: boolean;
    breakfastSelectionSent: boolean;
    invoiceSent: boolean;
    checkinInfosSent: boolean;
    checkinDayInfosSent: boolean;
    checkoutInfosSent: boolean;
    checkinTime: string;
    checkoutTime: string;
    cancelConfirmationSent: boolean;
    paymentLinkSent: boolean;
    pinCodes: PinCodes;
    allergies: string;
    housekeeping?: HSKDetails;
    breakfastSelection: BreakfastSelection[];
    foodjiVoucher?: {
      id: string;
      code: string;
    };
    urls?: { [key: string]: string };
    canActivateCode?: boolean;
    payment?: Payment;
    paymentLink?: {
      payerEmail: string;
    };
    arrival: Date;
    departure: Date;
    language: string;
    checkInReminderSent: boolean;
    guestRegistrationConfirmationSent: boolean;
    houseRuleSent: boolean;
    isOnlineCheckinDisabled: boolean;
    misconduct?: Misconduct;
    guests: Guest[];
    hasBreakfast: boolean;
    reservationStatus: EnumReservationStatus;
    covDocumentStatus: EnumCovDocumentStatus;
    onlineCheckinStatus?: {
      checkedIn: boolean;
      message: string;
      disabled: boolean;
      disabledReason?: string;
    };
  };
  property: {
    mandatoryOnlineCheckIn: boolean;
    mandatoryCovPassCheck: boolean;
    mandatoryIdUpload: boolean;
    timezone: string;
  };
}

export interface BreakfastSelection {
  date: Date;
  selection: {
    [key: string]: number;
  };
}

export interface MidClean {
  type?: MidCleanType;
  date?: string;
  lastClean?: string;
  noService?: NoServiceTimeslot[];
  scheduledByUser?: boolean;
  scheduledAt?: string;
}

export interface NoServiceTimeslot {
  start: string;
  end: string;
}

export enum MidCleanType {
  'Refresh' = 'refresh',
  'Clean' = 'clean',
  'Daily' = 'daily',
}

export interface HSKDetails {
  vip: boolean;
  comment: string;
  midClean?: MidClean;
}

export interface Payment {
  paymentAR?: boolean;
}

export enum LogLevel {
  'Info' = 'Info',
  'Error' = 'Error',
}

export interface EventLog {
  event: string;
  reservationId: string;
  message: string;
  additionalData?: {
    [key: string]: any;
  };
  timestamp?: string;
  timeStamp?: string;
  logLevel: LogLevel;
}

export interface Misconduct {
  types: string[];
  degree: string;
  additionalComment?: string;
}

export interface SuspiciousGuestRecord {
  recordId: string;
  propertyId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  city: string;
  country: string;
  email: string;
  archived: boolean;
  misconductTypes: string[];
  misconductDegree: MisconductDegree;
  reservations: {
    id: string;
    propertyId: string;
  }[];
  misconductComment: string;
}

export interface SuspiciousGuestRecordPaginationFilter {
  limit: number | undefined;
  offsetId?: string;
  offsetPropertyId?: string;
  lastEvaluatedKeyConfig?: {
    id: string;
    propertyId: string;
  };
}

export interface SuspiciousGuestRecordPaginationResult {
  suspiciousGuestRecords: SuspiciousGuestRecord[];
  paginationFilter: SuspiciousGuestRecordPaginationFilter;
}

export interface SelectedMisconductRecords {
  archived: string[];
  active: string[];
}

export enum EnumRecordArchivedState {
  Active = 'active',
  Archived = 'archived',
}

export enum ContentLanguage {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  CZ = 'cz',
  IT = 'it',
  FR = 'fr',
  NL = 'nl',
}

export interface ContentLanguageConfig {
  label: string;
  code: ContentLanguage;
}

export type TranslatedString = {
  [key in ContentLanguage]: string;
};

export enum QueryStrings {
  EVENT_LOGS = 'eventLogs',
  RESERVATION_DETAILS = 'resDetails',
  SUSPICIOUS_GUESTS = 'suspiciousGuests',
  BULK_MESSAGES = 'bulkMessages',
  MESSAGE_LIST = 'messageList',
  HSK_DELAY = 'hskDelay',
  PROOF_OF_ID = 'proofOfId',
}

export interface CovidFileDetails {
  name: string;
  type: string;
}

export interface CovidDocsUploadResponse {
  url: string;
  type: string;
  name: string;
}

export enum ManualMessageTypes {
  BOOKING_CONFIRMATION = 'bookingConfirmation',
  CHECKIN_INFO = 'checkinInfo',
  CHECKIN_REMINDER = 'checkinReminder',
  INVOICE = 'invoice',
  HOUSE_RULES = 'houseRules',
  CANCELLED = 'cancelled',
  EXTENSION_CONFIRMATION = 'extensionConfirmation',
  WELCOME_MESSAGE = 'welcomeMessage',
  PRE_CHECKOUT_INFO = 'preCheckoutInfo',
  CHECKOUT_REMINDER = 'checkoutReminder',
}

export interface ManualMessageList {
  type: ManualMessageTypes;
  isSent: boolean;
  sentBy?: 'v1' | 'v2';
}

export enum ApiMethods {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PUT = 'put',
  PATCH = 'PATCH',
}

export interface ApiSignature<T = null> {
  endpoint: string;
  method: ApiMethods;
  payload?: T;
}

export interface GetProofOfIdResponse {
  registrationStatus: 'complete' | 'incomplete';
  guests: Array<Guest>;
  reservationDocuments: Array<{
    downloadUrl: string;
  }>;
}

export interface Guest {
  firstName: string;
  secondSurname?: string;
  lastName: string;
  documents: Array<{
    isValid: boolean;
    documentSource?: 'OCR' | 'manual';
  }>;
  reviewRequired: boolean;
}

export interface HskDelayResponse {
  delayNotificationSent: boolean;
  delayUntil: Date;
  reservationId: string;
  unitId: string;
}
