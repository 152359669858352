import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { ManualMessageList, QueryStrings, ReservationDetails } from '../../types';
import { getMessageList, useQueryApiData } from '../../util/api';
import { ErrorMessage, SimpleCard } from '../common';
import { MessageField } from '../common/section';

type Props = Pick<ReservationDetails, 'reservation'>;

export const MessagingSection: React.FC<Props> = ({ reservation }) => {
  const reservationId = reservation.id;
  const { t } = useTranslation();
  const { data = [], isError, isLoading, refetch } = useQueryApiData(
    [reservationId, QueryStrings.MESSAGE_LIST],
    getMessageList
  );

  const messageList = data as ManualMessageList[];

  return (
    <SimpleCard title={t('sections.messages')}>
      {isLoading && <Typography>{t('general.loading')}</Typography>}
      {isError && <ErrorMessage message={t('general.error')} />}
      {messageList.map(field => (
        <MessageField
          refetch={refetch}
          key={field.type}
          label={t(`messages.${field.type}`)}
          value={field.isSent}
          type={field.type}
          hoverText={field.sentBy ? `Sent from ${field.sentBy}` : undefined}
        />
      ))}
    </SimpleCard>
  );
};
