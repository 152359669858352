import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { BulkMessagingDetails, ReservationDetails, WatchList } from '../../pages';
import Auth0Provider from '../auth/Auth0Provider';
import ProtectedRoute from './ProtectedRoute';

interface Routes {
  path: string;
  component: React.FC;
  protected?: boolean;
}

const routes: Routes[] = [
  { path: '/reservationDetails', component: ReservationDetails, protected: true },
  { path: '/bulkMessagingDetails', component: BulkMessagingDetails, protected: true },
  { path: '/watchList', component: WatchList, protected: true },
];

const useStyles = makeStyles({
  container: {
    height: '100%',
    overflow: 'auto',
  },
});

const Navigation: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Switch>
          {routes.map(route =>
            route.protected ? (
              <ProtectedRoute key={route.path} path={route.path} component={route.component} />
            ) : (
              <Route key={route.path} path={route.path}>
                {route.component}
              </Route>
            )
          )}
        </Switch>
      </div>
    </>
  );
};

const NavigationWithRouter: React.FC = () => (
  <Router>
    <Auth0Provider>
      <Navigation />
    </Auth0Provider>
  </Router>
);

export default NavigationWithRouter;
