import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  bottomBar: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
  },
});

interface Tab {
  to: string;
  label: string;
  icon: React.ReactElement;
  notifications?: number;
}

interface TabBarProps {
  tabs: Tab[];
  currentLocation: string;
}

const TabBar: React.FC<TabBarProps> = ({ tabs, currentLocation }) => {
  const initial = tabs.findIndex(x => x.to === currentLocation);
  const [value, setValue] = React.useState(initial >= 0 ? initial : 0);
  const { search } = useLocation();
  const classes = useStyles();

  return (
    <BottomNavigation
      value={value}
      className={classes.bottomBar}
      onChange={(event, newValue): void => {
        setValue(newValue);
      }}
      showLabels
    >
      {tabs.map(tab => (
        <BottomNavigationAction
          key={tab.to}
          component={Link}
          to={{ pathname: tab.to, search }}
          label={tab.label}
          icon={
            <Badge badgeContent={tab.notifications || 0} color="error">
              {tab.icon}
            </Badge>
          }
        />
      ))}
    </BottomNavigation>
  );
};

export default TabBar;
