const getEnvironmentConfig = (): {
  isProd: boolean;
  apiBase: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  tinyMceKey: string;
} => {
  const hosts: { [stage: string]: string } = {
    local: 'http://localhost:3000',
    development: 'https://dev.api.cosi-group.com',
    production: 'https://lmwfrzxola.execute-api.eu-central-1.amazonaws.com/prod',
  };

  const environment = process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV;
  const auth0Domain = environment === 'production' ? 'cosi.eu.auth0.com' : 'cosi-dev.eu.auth0.com';
  const auth0ClientId =
    environment === 'production' ? 'OXtwFlEVbi5f9uDqa9r2AKOUsVPnBjdF' : 'OdOgjuMPsbm3rZgEMKO5xhAfFJEFMsXr';
  const auth0Audience = environment === 'production' ? 'https://api.cosi-group.com' : 'https://dev.api.cosi-group.com';
  const tinyMceKey = 'iqvkfafx5lo33e9t36s05fntnjoovptc4yhrdrur3qzjf4m4';

  return {
    isProd: environment === 'production',
    apiBase: process.env.REACT_APP_API_URL || hosts[environment],
    auth0Domain,
    auth0ClientId,
    auth0Audience,
    tinyMceKey,
  };
};

const environmentConfig = getEnvironmentConfig();
export default environmentConfig;
