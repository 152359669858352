import {
  MutationFunction,
  QueryFunctionContext,
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { BulkEmailPayload, BulkSmsPayload } from '../components/bulkMessagingDetails/types';
import config from '../config';
import {
  ApiMethods,
  ApiSignature,
  GetProofOfIdResponse,
  HskDelayResponse,
  Payment,
  SuspiciousGuestRecordPaginationFilter,
} from '../types';

const getPmsToken = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  return params.get('token');
};

export const fetchData = async <T>(
  endpoint: string,
  method: ApiMethods,
  token: string,
  postParams?: any,
): Promise<T> => {
  try {
    const body = JSON.stringify(postParams);
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
    const res = await fetch(`${config.apiBase}/${endpoint}`, { method, body, headers });
    const json = await res.json();
    if (!json.success) {
      throw new Error(json.message);
    }
    return json.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchReservationDetails = (reservationId: string): ApiSignature => {
  return {
    endpoint: `apaleoExtensions/reservationDetails/${reservationId}`,
    method: ApiMethods.GET,
  };
};

export const updatePaymentFlag = (reservationId: string, data: Payment): ApiSignature<Payment> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/paymentFlag`,
  method: ApiMethods.POST,
  payload: data,
});

export const updateOnlineCheckin = (reservationId: string, data: any): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/onlineCheckin`,
  method: ApiMethods.POST,
  payload: data,
});

export const changeCovidDocumentsStatus = (reservationId: string, data: any): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/covidDocumentsStatus`,
  method: ApiMethods.POST,
  payload: data,
});

export const updateLanguage = (reservationId: string, data: any): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/language`,
  method: ApiMethods.POST,
  payload: data,
});

export const updateMisconductDetails = (reservationId: string, data: any): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/misconduct`,
  method: ApiMethods.POST,
  payload: data,
});

export const regeneratePinCodes = (reservationId: string): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/regeneratePinCodes`,
  method: ApiMethods.POST,
});

export const activateCode = (reservationId: string): ApiSignature => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/activatePincode`,
  method: ApiMethods.POST,
});

export const deleteCode = (reservationId: string): ApiSignature => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/deleteCode`,
  method: ApiMethods.POST,
});

export const revealCode = (referenceId: string): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/revealCode`,
  method: ApiMethods.POST,
  payload: { referenceId },
});

export const sendMessage = (messageType: string, reservationId: string, propertyId: string): ApiSignature<any> => ({
  endpoint: 'guestCommunication/message',
  method: ApiMethods.POST,
  payload: { messageType, reservationId, propertyId, token: getPmsToken() },
});

export const getMessageList = (reservationId: string): ApiSignature<any> => ({
  endpoint: `guestCommunication/message/${reservationId}`,
  method: ApiMethods.GET,
});

export const fetchEventLogs = (reservationId: string): ApiSignature => {
  return {
    endpoint: `apaleoExtensions/reservationLogs/${reservationId}`,
    method: ApiMethods.GET,
  };
};

export const sendBulkEmail = (emailDetails: BulkEmailPayload): ApiSignature<any> => ({
  endpoint: 'guestCommunication/bulkMessaging/email',
  method: ApiMethods.POST,
  payload: emailDetails,
});

export const sendBulkSms = (smsDetails: BulkSmsPayload): ApiSignature<any> => ({
  endpoint: 'guestCommunication/bulkMessaging/sms',
  method: ApiMethods.POST,
  payload: smsDetails,
});

export const sendPaymentLink = (reservationId: string, payerEmail: string): ApiSignature<any> => ({
  endpoint: 'finance/paymentLink',
  method: ApiMethods.POST,
  payload: { reservationId, payerEmail },
});

export const fetchSuspiciousGuestList = (filter: SuspiciousGuestRecordPaginationFilter): ApiSignature<any> => {
  const { limit, offsetId = '', offsetPropertyId = '' } = filter;
  return {
    endpoint: `gx/watchList/v2?limit=${limit}&offsetId=${offsetId}&offsetPropertyId=${offsetPropertyId}`,
    method: ApiMethods.GET,
  };
};

export const archiveMisconductRecords = (recordIds: string[]): ApiSignature<any> => ({
  endpoint: 'gx/watchList/archive',
  method: ApiMethods.POST,
  payload: { recordIds },
});

export const unarchiveMisconductRecords = (recordIds: string[]): ApiSignature<any> => ({
  endpoint: 'gx/watchList/unarchive',
  method: ApiMethods.POST,
  payload: { recordIds },
});

export const fetchBulkMessageSchedules = (apaleoPropertyId: string): ApiSignature => ({
  endpoint: `guestCommunication/bulkMessaging/schedules/property/${apaleoPropertyId}`,
  method: ApiMethods.GET,
});

export const deleteBulkMessageSchedule = (scheduleId: string): ApiSignature => ({
  endpoint: `guestCommunication/bulkMessaging/schedules/${scheduleId}`,
  method: ApiMethods.DELETE,
});

export const uploadCovidDocumentsApi = (reservationId: string, data: any): ApiSignature => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/covidDocumentsUpload`,
  method: ApiMethods.POST,
  payload: data,
});

export const saveCovidDocumentsApi = (reservationId: string, guestId: string, data: any): ApiSignature<any> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/guests/${guestId}/saveCovidDocuments`,
  method: ApiMethods.POST,
  payload: { files: data },
});

export const getProofOfId = (reservationId: string): ApiSignature<GetProofOfIdResponse> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/proofOfId`,
  method: ApiMethods.GET,
});

export const getHskDelay = (reservationId: string): ApiSignature<HskDelayResponse> => ({
  endpoint: `apaleoExtensions/reservationDetails/${reservationId}/hskDelay`,
  method: ApiMethods.GET,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-explicit-any
export const useQueryApiData = <W = any, T = any>(
  queryKeys: string | string[],
  getApiConfig: (...args: any[]) => ApiSignature<T>,
  options?: Omit<UseQueryOptions<W, string | string[], W, QueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<W, unknown> => {
  const { getAccessTokenSilently } = useAuth0();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const request = async (apiArgs: QueryFunctionContext<any, QueryKey>): Promise<W> => {
    const { queryKey } = apiArgs;
    const authToken = await getAccessTokenSilently();
    if (!authToken) {
      throw Error('Missing token');
    }
    const apiData = getApiConfig(...queryKey);
    const response = await fetchData<W>(apiData.endpoint, apiData.method, authToken, apiData.payload);
    return response;
  };

  const queryData = useQuery<W, string | string[]>(queryKeys, request, options);
  return queryData;
};

export const useMutateApiData = <W = any, T = any>(
  getApiConfig: (...args: any[]) => ApiSignature<T>,
  onSuccess?: ((data: W, variables: unknown, context: unknown) => void | Promise<unknown>) | undefined,
): UseMutationResult<W, unknown, any[], unknown> => {
  const { getAccessTokenSilently } = useAuth0();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const request: MutationFunction<W, any> = async (apiArgs) => {
    const authToken = await getAccessTokenSilently();
    if (!authToken) {
      throw Error('Missing token');
    }
    const apiData = getApiConfig(...apiArgs);
    const response = await fetchData<W>(apiData.endpoint, apiData.method, authToken, apiData.payload);
    return response;
  };

  const queryData = useMutation<W, unknown, any[]>(request, { onSuccess });
  return queryData;
};
