import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

import { ReservationDetails } from '../../types';
import { updateOnlineCheckin, useMutateApiData } from '../../util/api';
import { ErrorMessage } from '../common';
import { SelectField } from '../common/section';
import SwitchField from '../common/section/SwitchField';

interface MandatoryRegistrationProps {
  id: string;
  reservation: ReservationDetails['reservation'];
}

const DISABLEMENT_REASONS = [
  {
    label: 'Extension',
    value: 'extension',
  },
  { label: 'Relocation', value: 'relocation' },
  { label: 'Corp Bookings', value: 'corporateBookings' },
  { label: 'Wrong no of guests', value: 'invalidNoOfGuests' },
  { label: 'DCI scan or upload broken', value: 'brokenDci' },
  { label: 'Valid ID provided via manual upload', value: 'manualUpload' },
  { label: 'Missing links', value: 'missingLinks' },
  { label: 'Phone Verification Issue', value: 'phoneVerificationIssues' },
  { label: 'Other Tech issues ', value: 'other' },
];

const MandatoryRegistration: React.FC<MandatoryRegistrationProps> = ({ id, reservation }) => {
  const { t } = useTranslation();

  const { error, isLoading, mutateAsync: updateOnlineCheckinRequest } = useMutateApiData(updateOnlineCheckin);
  const { disabled = false, disabledReason: initialDisabledReason } = reservation.onlineCheckinStatus || {};
  const [isDisabled, setIsDisabled] = React.useState(disabled);
  const [disabledReason, setIsDisabledReason] = React.useState(initialDisabledReason);
  const [formError, setFormError] = React.useState<string | undefined>(undefined);

  const toggleIsDisabled = () => {
    setIsDisabled(state => !state);
  };

  const onSubmit = async (): Promise<void> => {
    setFormError(undefined);
    if (isDisabled && !disabledReason) {
      setFormError('Please specify reason');
      return;
    }
    await updateOnlineCheckinRequest([
      id,
      {
        isOnlineCheckinDisabled: isDisabled,
        disabledReason: isDisabled ? disabledReason : undefined,
      },
    ]);
  };

  return (
    <>
      <SwitchField
        field="mandatoryOnlineCheckIn"
        label={t('mandatoryRegistration.title')}
        onChange={toggleIsDisabled}
        value={isDisabled}
      />
      {isDisabled && (
        <SelectField
          field="disablementReason"
          label={t('Reason')}
          onChange={(field: string, value: string) => {
            setIsDisabledReason(value);
          }}
          value={disabledReason || ''}
          options={DISABLEMENT_REASONS}
          inputLabel={'Reason'}
        />
      )}

      <Box flexDirection="row">
        <Button variant="outlined" color="primary" onClick={onSubmit} disabled={isLoading}>
          {t('general.save')}
        </Button>

        {isLoading && <Typography>{t('general.loading')}</Typography>}
        {formError && <Typography color="error">{formError}</Typography>}
      </Box>

      {error && <ErrorMessage message={t('general.error')} />}
    </>
  );
};

export default MandatoryRegistration;
