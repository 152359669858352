import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { isWithinInterval } from 'date-fns';

import { EnumReservationStatus, ReservationDetails } from '../../types';
import { activateCode, deleteCode, regeneratePinCodes, revealCode, useMutateApiData } from '../../util/api';
import { ConfirmDialog, ErrorMessage } from '../common';
import { PinField, PinFieldWithButton } from '../common/section';

enum PinRequestType {
  RegenerateCode = 'regenerateCode',
  ActivateCode = 'activateCode',
  DeleteCode = 'deleteCode',
}

interface Props extends Pick<ReservationDetails, 'reservation'> {
  refetchReservation: () => Promise<any>;
}

export const usePincodesStyles = makeStyles((theme: Theme) => {
  const buttonStyle = {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  };
  return createStyles({
    container: {
      marginBottom: theme.spacing(1),
      wordBreak: 'break-word',
    },
    button: {
      ...buttonStyle,
    },
    primaryButton: {
      ...buttonStyle,
      color: '#35bf35',
      borderColor: '#35bf35',
    },
  });
});

const canActivateCode = (reservation: ReservationDetails['reservation']): boolean => {
  const arrival = new Date(reservation.arrival);
  arrival.setHours(0, 0, 0, 0);
  const departure = new Date(reservation.departure);
  return isWithinInterval(new Date(), { start: arrival, end: departure });
};

const PinCodes: React.FC<Props> = ({ reservation, refetchReservation }) => {
  const classes = usePincodesStyles();
  const { isLoading, error, mutateAsync: regeneratePinCodesRequest } = useMutateApiData(regeneratePinCodes);
  const {
    isLoading: activateCodeLoading,
    error: activateCodeError,
    mutateAsync: activateCodeRequest,
  } = useMutateApiData(activateCode);
  const {
    isLoading: deleteCodeLoading,
    error: deleteCodeError,
    mutateAsync: deleteCodeRequest,
  } = useMutateApiData(deleteCode);
  const {
    error: revealCodeError,
    mutateAsync: revealCodeRequest,
  } = useMutateApiData(revealCode);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [currentRequest, setCurrentRequest] = useState<PinRequestType>(PinRequestType.RegenerateCode);
  const [isBackupCodeVisible, setBackupCodeVisibility] = useState<boolean>(false);
  const { t } = useTranslation();

  const onClose = async (accepted: boolean): Promise<void> => {
    if (!accepted) {
      setConfirmModalOpen(false);
      return;
    }
    switch (currentRequest) {
      case PinRequestType.RegenerateCode:
        await regeneratePinCodesRequest([reservation.id]);
        break;
      case PinRequestType.ActivateCode:
        await activateCodeRequest([reservation.id]);
        break;
      case PinRequestType.DeleteCode:
        await deleteCodeRequest([reservation.id]);
        break;
      default:
        return;
    }
    setConfirmModalOpen(false);
    await refetchReservation();
  };

  const onRegenerateCode = (): void => {
    setCurrentRequest(PinRequestType.RegenerateCode);
    setConfirmModalOpen(true);
  };

  const onActivateCode = (): void => {
    setCurrentRequest(PinRequestType.ActivateCode);
    setConfirmModalOpen(true);
  };

  const onDeleteCode = (): void => {
    setCurrentRequest(PinRequestType.DeleteCode);
    setConfirmModalOpen(true);
  }

  const onRevealCode = async (): Promise<void> => {
    await revealCodeRequest([reservation.id]);
    setBackupCodeVisibility(true);
  }

  const confirmTitle = {
    [PinRequestType.RegenerateCode]: t('pinCodes.regenerateCode'),
    [PinRequestType.ActivateCode]: t('pinCodes.activateCode'),
    [PinRequestType.DeleteCode]: t('pinCodes.deleteCode'),
  };
  const confirmText = {
    [PinRequestType.RegenerateCode]: t('pinCodes.regenerateCodeExplained'),
    [PinRequestType.ActivateCode]: t('pinCodes.activateCodeExplanation'),
    [PinRequestType.DeleteCode]: t('pinCodes.deleteCodeExplained'),
  };

  const { pinCodes } = reservation;
  const hasPinCode = !!pinCodes?.pinComfortCode || !!pinCodes?.comfortLink;
  const isReservationInHouse = reservation.reservationStatus === EnumReservationStatus.InHouse;

  return (
    <div className={classes.container}>
      <PinField code={pinCodes.pinComfortCode} label={t('pinCodes.comfortCode')} />
      <PinField code={pinCodes.comfortLink} label={t('pinCodes.comfortLink')} />
      <PinFieldWithButton
        code={pinCodes.backupCode}
        label={t('pinCodes.backupCode')}
        showButton={!isBackupCodeVisible}
        buttonLabel={t('pinCodes.reveal')}
        onClick={onRevealCode}
      />
      <Box flexDirection="row">
        {reservation.canActivateCode && (
          <Button
            className={classes.primaryButton}
            variant="outlined"
            color="inherit"
            onClick={onActivateCode}
            disabled={activateCodeLoading || !canActivateCode(reservation)}
          >
            {t('pinCodes.activateCode')}
          </Button>
        )}
        {(hasPinCode || isReservationInHouse) && (
          <Button
            className={classes.primaryButton}
            variant="outlined"
            color="inherit"
            onClick={onRegenerateCode}
            disabled={isLoading}
          >
            {t('pinCodes.regenerateCode')}
          </Button>
        )}
        {hasPinCode && (
          <Button
            className={classes.primaryButton}
            variant="outlined"
            color="inherit"
            onClick={onDeleteCode}
            disabled={deleteCodeLoading}
          >
            {t('pinCodes.deleteCode')}
          </Button>
        )}
      </Box>
      <ConfirmDialog
        open={confirmModalOpen}
        confirmTitle={confirmTitle[currentRequest]}
        confirmText={confirmText[currentRequest]}
        onClose={onClose}
        loading={isLoading || activateCodeLoading}
      />
      {(error || activateCodeError || deleteCodeError || revealCodeError) && <ErrorMessage message={t('general.error')} />}
    </div>
  );
};

export default PinCodes;
