import React from 'react';
import { Card, CardActions, CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      maxWidth: 400,
      marginTop: theme.spacing(3),
      boxShadow: '0 5px 80px rgba(0,0,0,0.08), 0 3px 100px rgba(0,0,0,0.05)',
      borderRadius: '7px',
      position: 'relative',
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 24,
    },
  })
);

interface SimpleCardProps {
  title: string;
  rightTitle?: string;
  color?: string;
  cardActions?: JSX.Element;
  maxWidth?: string | number;
  maxHeight?: string | number;
  overflowBehaviour?: string;
}

const SimpleCard: React.FC<SimpleCardProps> = ({
  color,
  title,
  rightTitle,
  children,
  cardActions,
  maxWidth,
  overflowBehaviour,
  maxHeight,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} style={{ backgroundColor: color, maxWidth, overflow: overflowBehaviour, maxHeight }}>
      <CardContent>
        <div className={classes.titleRow}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {title}
          </Typography>
          <Typography color="initial" gutterBottom>
            {rightTitle}
          </Typography>
        </div>
        {children}
      </CardContent>
      {cardActions && <CardActions>{cardActions}</CardActions>}
    </Card>
  );
};

export default SimpleCard;
