import React from 'react';
import { createStyles, FormControl, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

interface TextAreaFieldProps {
  onChange: (x: any, y: string) => void;
  label?: string;
  field?: string;
  value?: string;
  placeHolder?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      minWidth: '100%',
      marginBottom: theme.spacing(2),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
  })
);

const TextAreaField: React.FC<TextAreaFieldProps> = ({ onChange, label, field, value, placeHolder }) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onChange(field || label, event.target.value as string);
  };

  return (
    <FormControl className={classes.form}>
      <Typography variant="subtitle1" className={classes.label}>
        <strong>{label}:</strong>
      </Typography>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        onChange={handleChange}
        value={value}
        placeholder={placeHolder}
      />
    </FormControl>
  );
};

export default TextAreaField;
