import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '50px',
    },
  })
);

export const WatchListHeader: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return <h1 className={classes.root}>{t('guestWatchList.pageHeader')}</h1>;
};
