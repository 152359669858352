import React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  ClickAwayListener,
  createStyles,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

interface Option<T extends string> {
  readonly id: T;
  readonly label: string;
}

interface SplitButtonProps<T extends string> extends Pick<ButtonProps, 'variant' | 'color'> {
  options: readonly Option<T>[];
  onClick: (id: Option<T>['id']) => void;
  className?: string;
}

const useStyles = makeStyles(() => {
  return createStyles({
    mainButton: {
      flexGrow: 1,
    },
  });
});

export const SplitButton = <T extends string = string>({
  options,
  onClick,
  className,
  ...buttonProps
}: SplitButtonProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();

  const handleClick = () => {
    onClick(options[selectedIndex].id);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
    onClick(options[index].id);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <ButtonGroup {...buttonProps} ref={anchorRef} className={className}>
      <Button size="small" onClick={handleClick} className={classes.mainButton}>
        {options[selectedIndex].label}
      </Button>

      {options.length > 1 && (
        <>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDown />
          </Button>

          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </ButtonGroup>
  );
};
