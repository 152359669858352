/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, makeStyles } from '@material-ui/styles';

import { formatTimeStamp } from '../../util';
import { deleteBulkMessageSchedule } from '../../util/api';
import { DeleteDialog } from '../common';
import { BulkMessageEmailTypes, BulkMessageEmailTypesEnum, MessageTypes, ScheduledMessage } from './types';

interface BulkMessageSectionProps {
  propertyId: string | null;
  scheduledMessages: ScheduledMessage[];
  fetchBulkMessageSchedules: (...apiArgs: any[]) => Promise<void>;
  getEditableSchedule: (schedule: ScheduledMessage) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      height: '600px',
      overflow: 'scroll',
    },
    content: {
      maxWidth: '500px',
      maxHeight: '100px',
      overflowY: 'scroll',
    },
  })
);

export const BulkMessageScheduleTable: React.FC<BulkMessageSectionProps> = ({
  fetchBulkMessageSchedules,
  propertyId,
  scheduledMessages,
  getEditableSchedule,
}) => {
  const classes = useStyles();
  const [deleteScheduleMessageId, setDeleteScheduleMessageId] = useState<string | null>();

  const { t } = useTranslation();

  const columns = [
    {
      field: 'actions',
      headerName: t('bulkMessaging.columnHeading.actions'),
    },
    {
      field: 'status',
      headerName: t('bulkMessaging.columnHeading.status'),
    },
    {
      field: 'type',
      headerName: t('bulkMessaging.columnHeading.type'),
    },
    {
      field: 'scheduledAt',
      headerName: t('bulkMessaging.columnHeading.scheduledAt'),
    },
    {
      field: 'reservationStatus',
      headerName: t('bulkMessaging.columnHeading.reservationStatus'),
    },
    {
      field: 'reservationIds',
      headerName: t('bulkMessaging.columnHeading.reservationIds'),
    },
    {
      field: 'content',
      headerName: t('bulkMessaging.columnHeading.content'),
    },
  ];

  return (
    <div className={classes.tableContainer}>
      <DeleteDialog
        open={!!deleteScheduleMessageId}
        onSaved={async () => {
          setDeleteScheduleMessageId(null);
          await fetchBulkMessageSchedules(propertyId);
        }}
        onClose={() => {
          setDeleteScheduleMessageId(null);
        }}
        item={deleteScheduleMessageId}
        apiMethod={deleteBulkMessageSchedule}
        confirmTitle={t('bulkMessaging.deletedConfirmTitle')}
        confirmText={t('bulkMessaging.deletedConfirmText')}
      />
      <TableContainer>
        <Table aria-labelledby="bulk-messaging" size={'medium'}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.field} align="left">
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduledMessages.map((scheduledMessage, index) => {
              const labelId = `scheduled-message-${index}`;
              return (
                <TableRow hover tabIndex={-1} key={scheduledMessage.id}>
                  <TableCell component="th" id={labelId} scope="row" padding="none">
                    <strong>
                      {!scheduledMessage.isCompleted && (
                        <IconButton
                          aria-label="delete"
                          size="medium"
                          onClick={() => getEditableSchedule(scheduledMessage)}
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      )}

                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => setDeleteScheduleMessageId(scheduledMessage.id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </strong>
                  </TableCell>
                  <TableCell key={`messageType-${index}`} align="left">
                    {scheduledMessage.isCompleted ? t('bulkMessaging.success') : t('bulkMessaging.pending')}
                  </TableCell>
                  <TableCell key={`messageType-${index}`} align="left">
                    {scheduledMessage.type?.toUpperCase()}
                  </TableCell>
                  <TableCell key={`scheduled-${index}`} align="left">
                    {scheduledMessage.scheduledAt ? formatTimeStamp(scheduledMessage.scheduledAt) : ''}
                  </TableCell>
                  <TableCell key={`reservationStatus-${index}`} align="left">
                    {!scheduledMessage.reservationIds?.length ? scheduledMessage.reservationStatus : ''}
                  </TableCell>
                  <TableCell key={`reservationIds-${index}`} align="left">
                    {scheduledMessage.reservationIds?.join(', ')}
                  </TableCell>
                  <TableCell key={`content-${index}`} align="left">
                    {scheduledMessage.emailType === BulkMessageEmailTypesEnum.individualEmail ? (
                      <div
                        className={classes.content}
                        dangerouslySetInnerHTML={{ 
                          __html: scheduledMessage.type === MessageTypes.SMS
                            ? scheduledMessage.content.en.replace(/\n/g, "<br />")
                            : scheduledMessage.content.en
                        }}
                      />
                    ) : (
                      <div className={classes.content}>{BulkMessageEmailTypes[scheduledMessage.emailType]}</div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
