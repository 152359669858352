import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

import { ConfirmDialog } from '..';
import { sendMessage, useMutateApiData } from '../../../util/api';
import SectionField from './SectionField';

interface MessageFieldProps {
  type: string;
  label: string;
  value?: boolean;
  hoverText?: string;
  refetch: () => Promise<any>;
}

const useStyles = makeStyles({
  button: {
    marginLeft: 'auto',
  },
});

const MessageField: React.FC<MessageFieldProps> = ({ type, label, value, hoverText, refetch }) => {
  const { isLoading, mutateAsync: triggerMessage } = useMutateApiData(sendMessage);
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const onClose = async (accepted: boolean): Promise<void> => {
    if (!accepted) {
      setConfirmModalOpen(false);
      return;
    }
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const resId = params.get('reservationId');
    const propertyId = params.get('propertyId');
    await triggerMessage([type, resId, propertyId]);
    refetch();
    setConfirmModalOpen(false);
  };
  const onSend = (): void => setConfirmModalOpen(true);
  const sendAction = value ? t('messages.resend') : t('messages.sendNow');
  return (
    <SectionField label={label}>
      {value !== undefined && (
        <Tooltip title={hoverText || ''} arrow>{value ? <Check color="primary" /> : <Close color="error" />}</Tooltip>
      )}
      <Button className={classes.button} variant="outlined" color="primary" onClick={onSend}>
        {sendAction}
      </Button>
      <ConfirmDialog
        open={confirmModalOpen}
        confirmTitle={`${sendAction} ${label}`}
        confirmText="Confirm"
        onClose={onClose}
        loading={isLoading}
      />
    </SectionField>
  );
};

export default MessageField;
