/* eslint-disable react/display-name */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { Login } from '../auth';
import { Loading } from '../common';

export interface ProtectedRouteProps extends RouteProps {
  component: React.FC;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...args }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {!isLoading && isAuthenticated ? (
        <>
          <Route
            component={withAuthenticationRequired(component, {
              onRedirecting: () => <Loading />,
            })}
            {...args}
          />
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default ProtectedRoute;
