import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

const Logout: React.FC = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() =>
        logout({
          returnTo: window.location.href,
        })
      }
    >
      {t('general.logout')}
    </Button>
  );
};

export default Logout;
