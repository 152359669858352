import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider as Auth0 } from '@auth0/auth0-react';

import environmentConfig from '../../config';

interface Auth0Provider {
  children: React.ReactElement;
}

const Auth0Provider: React.FC<Auth0Provider> = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0
      domain={environmentConfig.auth0Domain}
      clientId={environmentConfig.auth0ClientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
      audience={environmentConfig.auth0Audience}
    >
      {children}
    </Auth0>
  );
};

export default Auth0Provider;
