import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ContentLanguage, ContentLanguageConfig } from '../../types';

const useStyles = makeStyles(() =>
  createStyles({
    langTab: {
      minWidth: 90,
    },
    langContainer: {
      marginBottom: 10,
    },
  })
);

interface LanguageTabsProps {
  onLangChange: (lang: ContentLanguage) => void;
  langs: ContentLanguageConfig[];
}

const LanguageTabs: React.FC<LanguageTabsProps> = ({ onLangChange, langs }): JSX.Element => {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number): void => {
    setValue(newValue);
    onLangChange(langs[newValue].code);
  };

  return (
    <Grid container className={classes.langContainer}>
      <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleTabChange}>
        {langs.map(lang => (
          <Tab key={lang.code} label={lang.label} className={classes.langTab} />
        ))}
      </Tabs>
    </Grid>
  );
};

export default LanguageTabs;
