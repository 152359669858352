import React from 'react';

import SectionField from './SectionField';

interface PinFieldProps {
  code?: string;
  label: string;
}

const PinField: React.FC<PinFieldProps> = ({ code, label }) => (
  <>{code && <SectionField label={label}>{code}</SectionField>}</>
);

export default PinField;
