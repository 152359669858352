import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { QueryStrings } from '../../../types';
import { fetchEventLogs, useQueryApiData } from '../../../util/api';
import { ErrorMessage, SimpleCard } from '../../common';
import Logs from './Logs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
    },
    contentToggleButton: {
      display: 'flex',
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    loaderWrapper: {
      position: 'absolute',
      top: theme.spacing(2.5),
      right: theme.spacing(4),
    },
  })
);

interface Props {
  reservationId: string;
  timezone: string;
}

const EventsLog: React.FC<Props> = ({ reservationId, timezone }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data = { eventsLog: [] }, isError, isLoading, isFetching } = useQueryApiData(
    [reservationId, QueryStrings.EVENT_LOGS],
    fetchEventLogs,
    {
      refetchInterval: 10000,
    }
  );

  const [showMore, setShowMore] = useState(false);

  const shouldTruncate = (data?.eventsLog || []).length > 5;
  const isLoadingComplete = !isLoading && !isError;
  if (isLoadingComplete && (!data?.eventsLog || !data.eventsLog.length)) {
    return null;
  }

  const onShowMoreClick = (): void => {
    setShowMore(!showMore);
  };

  return (
    <SimpleCard title={t('eventsLog.title')} maxWidth={1000} overflowBehaviour="scroll" maxHeight={600}>
      <div className={classes.container}>
        {isLoading && <Typography>{t('general.loading')}</Typography>}
        {isError && <ErrorMessage message={t('general.error')} />}
        {isFetching && (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={30} />
          </div>
        )}
        {isLoadingComplete && <Logs eventsLog={data?.eventsLog || []} timezone={timezone} showMore={showMore} />}
      </div>
      {isLoadingComplete && shouldTruncate && (
        <Button
          startIcon={!showMore ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          variant="text"
          color="primary"
          onClick={onShowMoreClick}
          className={classes.contentToggleButton}
        >
          {!showMore ? t('eventsLog.showMore') : t('eventsLog.showLess')}
        </Button>
      )}
    </SimpleCard>
  );
};

export default EventsLog;
