import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { ReservationDetails } from '../../types';
import { SimpleCard } from '../common';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      padding: '5px 20px',
    },
  });
});

type Props = Pick<ReservationDetails, 'reservation'>;

interface UrlContent {
  label: string;
  url: string;
}

enum EnumLinkLabel {
  guestRegistration = 'DCi',
  tripManagement = 'Trip Manager',
  breakfast = 'Breakfast selection',
  extensionTripManagement = 'Extension of stay',
  cancellationTripManagement = 'Cancellation',
  extrasTripManagement = 'Extras',
}

const GuestLinks: React.FC<Props> = ({ reservation }: Props) => {
  const classes = useStyles(); // eslint-disable-line
  const { t } = useTranslation();

  const renderLink = ({ label, url }: UrlContent) => {
    return (
      <Grid key={label} container spacing={1} style={{ paddingTop: '5px', paddingBottom: '20px' }}>
        <Grid item sm={12}>
          <Box>
            <Typography component="span">{label}</Typography>
            <Typography>
              <strong>{url}</strong>
            </Typography>
            <Divider />
          </Box>
        </Grid>

        {/* TODO: Replace with copy button */}
        {/* <Grid item sm={4}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            href={url}
            target="_blank"
            endIcon={<OpenInNew />}
          >
            {t('general.open')}
          </Button>
        </Grid> */}
      </Grid>
    );
  };

  const renderUrlContent = () => {
    const { urls = {} } = reservation;
    const urlContent: { [urlType: string]: UrlContent } = {};
    Object.entries(urls).forEach(([key, value]) => {
      const label = EnumLinkLabel[key as keyof typeof EnumLinkLabel];
      if (!label) {
        return;
      }

      const urlWithUtmParameters = new URL(value);
      urlWithUtmParameters.searchParams.set('utm_source', 'apaleo');

      urlContent[key] = {
        label,
        url: urlWithUtmParameters.href,
      };
    });
    if (urlContent?.guestRegistration && urlContent?.breakfast) {
      const { origin, search } = new URL(urls.guestRegistration);
      urlContent.breakfast.url = `${origin}/breakfast${search}`;
    }

    return Object.values(urlContent).map(({ label, url }) => {
      return renderLink({ label, url });
    });
  };

  return <SimpleCard title={t('sections.guestLinks')}>{renderUrlContent()}</SimpleCard>;
};

export default GuestLinks;
