import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import { Payment, ReservationDetails } from '../../types';
import { sendPaymentLink, updatePaymentFlag, useMutateApiData } from '../../util/api';
import { ErrorMessage } from '../common';
import { SectionField, TextInputField } from '../common/section';
import SwitchField from '../common/section/SwitchField';

type Props = Pick<ReservationDetails, 'reservation'>;

const PaymentDetails: React.FC<Props> = ({ reservation }) => {
  const { t } = useTranslation();

  const { id, payment, paymentLink } = reservation;
  const { error, isLoading, mutateAsync: updatePaymentFlagRequest } = useMutateApiData(updatePaymentFlag);
  const { error: paymentLinkError, isLoading: paymentLinkLoading, mutate: paymentLinkRequest } = useMutateApiData(
    sendPaymentLink
  );

  const [details, setDetails] = React.useState<Payment>({
    paymentAR: payment?.paymentAR || false,
  });
  const [payerEmail, setPayerEmail] = React.useState<string>(paymentLink?.payerEmail || '');

  const onPaymentARChange = (detail: keyof Payment, value: any): void => {
    setDetails({
      ...details,
      [detail]: value,
    });
  };

  const onSubmit = async (): Promise<void> => {
    await updatePaymentFlagRequest([id, details]);
  };

  const onPayerEmailChange = (_: string, value: string): void => {
    setPayerEmail(value);
  };

  const onSubmitPaymentLinkDetails = async (): Promise<void> => {
    await paymentLinkRequest([id, payerEmail]);
  };

  const { paymentLinkSent } = reservation;
  return (
    <>
      <SwitchField
        field="paymentAR"
        label={t('paymentDetails.paymentAR')}
        onChange={onPaymentARChange}
        value={details.paymentAR}
      />
      <Box flexDirection="row">
        <Button variant="outlined" color="primary" onClick={onSubmit} disabled={isLoading}>
          {t('general.save')}
        </Button>
        {isLoading && <Typography>{t('general.loading')}</Typography>}
      </Box>
      {error && <ErrorMessage message={t('general.error')} />}
      <SectionField label={t('sections.paymentLink')}>
        {paymentLinkSent ? <Check color="primary" /> : ''}
        <TextInputField
          field="payerEmail"
          onChange={onPayerEmailChange}
          value={payerEmail}
          helperText={t('paymentDetails.paymentLinkHelper')}
          placeholder={t('paymentDetails.payerEmail')}
        />
      </SectionField>
      <Box flexDirection="row">
        <Button variant="outlined" color="primary" onClick={onSubmitPaymentLinkDetails} disabled={isLoading}>
          {t('paymentDetails.sendPaymentLink')}
        </Button>
        {paymentLinkLoading && <Typography>{t('general.loading')}</Typography>}
      </Box>
      {paymentLinkError && <ErrorMessage message={t('general.error')} />}
    </>
  );
};

export default PaymentDetails;
