import React from 'react';

import { useStyles } from './styles';
import { S3FileProps } from './types';

export const S3Pdf = ({ downloadUrl, isPreview }: S3FileProps) => {
  const classes = useStyles();
  if (isPreview) {
    return <div className={classes.thumbnail}>PDF</div>;
  }

  return <iframe src={downloadUrl} frameBorder="0" className={classes.pdfIframe} />;
};
