import React from 'react';
import { createStyles, FormControl, makeStyles, Theme, Typography } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

import environmentConfig from '../../../config';

interface HtmlFieldProps {
  onChange: (x: string, y: any) => void;
  label?: string;
  field?: string;
  value?: string;
  placeHolder?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      minWidth: '100%',
      marginBottom: theme.spacing(2),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
  })
);

const HtmlInputField: React.FC<HtmlFieldProps> = ({ onChange, label, value, placeHolder }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.form}>
      <Typography variant="subtitle1" className={classes.label}>
        <strong>{label}:</strong>
      </Typography>

      <Editor
        apiKey={environmentConfig.tinyMceKey}
        textareaName={label}
        onEditorChange={onChange}
        value={value}
        inline={false}
        init={{
          height: 300,
          placeholder: placeHolder,

          paste_as_text: true,
          plugins: ['advlist autolink lists link image charmap preview anchor', 'media table paste'],
          toolbar:
            'undo redo | formatselect | bold italic color| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | media ',

          file_picker_types: 'media',
          file_picker_callback: (callback: any): void => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'video/*');

            input.click();
          },
        }}
      />
    </FormControl>
  );
};

export default HtmlInputField;
