import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutateApiData } from '../../util/api';
import { ConfirmDialog, ErrorMessage } from '.';
import { ApiSignature } from '../../types';

export interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onSaved: () => void;
  item: any;
  apiMethod: (...args: any[]) => ApiSignature<any>;
  confirmTitle: string;
  confirmText: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  onClose,
  open,
  onSaved,
  item,
  apiMethod,
  confirmTitle,
  confirmText,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteFun, isLoading, isError } = useMutateApiData(apiMethod);

  const onConfirmed = async (confirmed: boolean): Promise<void> => {
    if (confirmed) {
      await deleteFun([item]);
      onSaved();
    } else {
      onClose();
    }
  };

  return (
    <ConfirmDialog
      confirmTitle={confirmTitle}
      confirmText={confirmText}
      open={open}
      loading={isLoading}
      onClose={onConfirmed}
    >
      {isError && <ErrorMessage message={t('general.error')} />}
    </ConfirmDialog>
  );
};

export default DeleteDialog;
