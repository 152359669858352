import { ContentLanguage, TranslatedString } from '../../types';

export interface BulkSmsPayload {
  propertyId: string;
  content: TranslatedString;
  reservationStatus: string;
  isScheduled?: boolean;
  scheduledAt?: string;
  createdBy: string;
  reservationDate?: string;
  emailType: BulkMessageEmailTypesEnum;
  reservationIds?: string[];
  isCompleted?: boolean;
}

export interface BulkEmailPayload extends BulkSmsPayload {
  subject: TranslatedString;
}

export interface ScheduledMessage extends BulkEmailPayload {
  id: string;
  type: MessageTypes;
}

export enum RESERVATION_STATUSES {
  ARRIVAL = 'Arrival',
  STAY = 'Stay',
  CREATION = 'Creation',
}

export const RESERVATION_STATUS_OPTIONS = [
  RESERVATION_STATUSES.ARRIVAL,
  RESERVATION_STATUSES.STAY,
  RESERVATION_STATUSES.CREATION,
];

export enum MessageTypes {
  SMS = 'sms',
  EMAIL = 'email',
}

export interface BulkMessageFilters {
  reservationStatus: string;
  lang?: string;
  reservationDate?: string;
  reservationIds?: string;
  emailType?: BulkMessageEmailTypesEnum;
}

export enum EnumFilterTypes {
  RESERVATION_STATUS = 'Reservation status',
  RESERVATION_ID = 'Reservation Ids',
}

export const TRANSLATABLE_STRING: TranslatedString = {
  [ContentLanguage.EN]: '',
  [ContentLanguage.DE]: '',
  [ContentLanguage.ES]: '',
  [ContentLanguage.ES]: '',
  [ContentLanguage.FR]: '',
  [ContentLanguage.NL]: '',
  [ContentLanguage.CZ]: '',
  [ContentLanguage.IT]: '',
};

export enum BulkMessageEmailTypesEnum {
  individualEmail = 'individualEmail',
  bookingConfirmation = 'bookingConfirmation',
  guestRegistration = 'guestRegistration',
  paymentLink = 'paymentLink',
  houseRules = 'houseRules',
}

export const BulkMessageEmailTypes = {
  [BulkMessageEmailTypesEnum.individualEmail]: 'Individual E-mail',
  [BulkMessageEmailTypesEnum.bookingConfirmation]: 'Booking Confirmation',
  [BulkMessageEmailTypesEnum.guestRegistration]: 'Online Guest Registration',
  [BulkMessageEmailTypesEnum.paymentLink]: 'Unpaid Reservation Payment',
  [BulkMessageEmailTypesEnum.houseRules]: 'House Rules',
};

export const FILTER_TYPES = [EnumFilterTypes.RESERVATION_STATUS, EnumFilterTypes.RESERVATION_ID];
