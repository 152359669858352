import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { ContainerProps } from '@material-ui/core/Container';

const useStyles = makeStyles({
  root: {
    height: '100%',
    overflow: 'auto',
  },
});

const PageContainer: React.FC<ContainerProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="xl" {...rest}>
        {children}
      </Container>
    </div>
  );
};

export default PageContainer;
