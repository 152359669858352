import React from 'react';
import { useTranslation } from 'react-i18next';

import { RecipientsField } from '../../common/section';

interface InvoiceRecipientsProps {
  invoiceRecipients?: string[];
}

const InvoiceRecipients: React.FC<InvoiceRecipientsProps> = ({ invoiceRecipients }) => {
  const { t } = useTranslation();

  if (!invoiceRecipients || invoiceRecipients.length === 0) {
    return <RecipientsField label={t('recipientsEmail.noEmails')} />;
  }

  return (
    <>
      {invoiceRecipients.map((email: string, index: number) => {
        return <RecipientsField key={index} label={email} />;
      })}
    </>
  );
};

export default InvoiceRecipients;
