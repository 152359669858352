import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

interface SectionFieldProps {
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const SectionText: React.FC<SectionFieldProps> = ({ label, children }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.sectionField} container justify="flex-start" alignItems="center">
      <Typography>
        <strong>{`${label}`}</strong>
      </Typography>
      &nbsp;
      {children}
    </Grid>
  );
};

export default SectionText;
