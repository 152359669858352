import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  thumbnail: {
    backgroundColor: '#ccc',
    aspectRatio: '1',
    display: 'grid',
    placeContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageWrapper: {
    overflow: 'hidden',
  },
  pdfIframe: {
    width: '60em',
    maxWidth: '100%',
    height: '100em',
    maxHeight: '100%',
  },
});
