import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { ErrorMessage } from '../common';
import { SwitchField } from '../common/section';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    toolBar: {
      width: 300,
    },
    toolBarTitle: {
      flex: '1 1 100%',
    },
  })
);

interface WatchListToolBarProps {
  onChange: (x: any, y: boolean) => void;
  numOfSelections: number;
  checked: boolean;
  actionLabel: string;
  loading: boolean;
  error?: boolean;
}

export const WatchListToolBar: React.FC<WatchListToolBarProps> = ({
  onChange,
  numOfSelections,
  checked,
  actionLabel,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolBar}>
      <Typography className={classes.toolBarTitle} color="inherit" variant="subtitle1" component="div">
        {numOfSelections} Selections
      </Typography>
      <SwitchField label={actionLabel} onChange={onChange} value={checked} disabled={loading} />
      {error && <ErrorMessage message={t('general.error')} />}
    </Toolbar>
  );
};
