import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/styles';

import { BulkMessageSection } from '../components/bulkMessagingDetails/BulkMessageSection';
import { ScheduledMessage } from '../components/bulkMessagingDetails/types';
import { ErrorMessage, Loading, PageContainer } from '../components/common';
import { QueryStrings } from '../types';
import { fetchBulkMessageSchedules, useQueryApiData } from '../util/api';

const useStyles = makeStyles(() =>
  createStyles({
    pageContainer: {
      position: 'relative',
    },
    loader: {
      position: 'absolute',
      right: '450px',
      top: '15px',
    },
  })
);

const BulkMessagingDetails: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const propertyId = params.get('propertyId') || '';

  const { data, isLoading, isError, refetch } = useQueryApiData<ScheduledMessage[]>(
    [propertyId, QueryStrings.BULK_MESSAGES],
    fetchBulkMessageSchedules,
    { refetchInterval: 1000 }
  );

  if (isError) {
    return <ErrorMessage message={t('general.error')} showLogout={true} />;
  }

  if (!data) {
    return <Loading />;
  }

  return (
    <PageContainer className={classes.pageContainer}>
      <>
        {isLoading && <div className={classes.loader}></div>}
        <BulkMessageSection fetchBulkMessageSchedules={refetch} propertyId={propertyId} scheduledMessages={data} />
      </>
    </PageContainer>
  );
};

export default BulkMessagingDetails;
