import React from 'react';
import { FormControl, makeStyles, TextField } from '@material-ui/core';

interface TextInputFieldProps {
  onChange: (x: any, y: string) => void;
  label?: string;
  field?: string;
  value?: string;
  helperText?: string;
  placeholder?: string;
}

const useStyles = makeStyles({
  form: {
    minWidth: '100%',
  },
});

const TextInputField: React.FC<TextInputFieldProps> = ({ onChange, label, field, value, helperText, placeholder }) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onChange(field || label, event.target.value as string);
  };

  return (
    <FormControl className={classes.form}>
      <TextField
        label={label}
        onChange={handleChange}
        value={value}
        fullWidth
        multiline
        helperText={helperText}
        placeholder={placeholder}
      />
    </FormControl>
  );
};

export default TextInputField;
