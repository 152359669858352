import React, { Fragment, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/styles';
import { formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { TFunction } from 'i18next';

import { EventLog, LogLevel, PinCodes } from '../../../types';
import { formatReadTimestamp } from '../../../util';
import { ConfirmDialog } from '../../common';
import { PinField, SectionField } from '../../common/section';

const useStyles = makeStyles({
  visibilityIcon: {
    marginLeft: 5,
    verticalAlign: 'sub',
    cursor: 'pointer',
  },
  errorIcon: {
    color: '#ce1313',
    marginLeft: -10,
    marginRight: 5,
    verticalAlign: 'middle',
    height: 25,
    width: 30,
  },
  stepCompletedIcon: {
    display: 'none',
  },
});

const ADDITIONAL_FIELDS: IndexSignature = {
  pinAccessCode: 'Access Code',
  pinCodes: 'pinCodes',
  pinComfortCode: 'Comfort Code',
};

interface IndexSignature {
  [key: string]: string;
}

type RenderSignature = ReactElement | null;

const renderPincodes = (pinCodes: PinCodes, t: TFunction): ReactElement | null => {
  return (
    <>
      {Object.entries(pinCodes).map(([key, value]: [string, any]) => {
        const keyMap: IndexSignature = {
          pinComfortCode: 'comfortCode',
          pinAccessCode: 'activationCode',
        };
        return <PinField key={`${value}-${key}`} code={value} label={t(`pinCodes.${keyMap[key] || key}`)} />;
      })}
    </>
  );
};

const renderAssignedRoomData = (rooms: any): ReactElement | null => {
  const { unit } = rooms.timeSlices[0];

  return (
    <SectionField key={`${unit}-${Math.random()}`} label={String(ADDITIONAL_FIELDS.roomNumber)}>
      {unit.name}
    </SectionField>
  );
};

const renderAdditionalData = (modalData: IndexSignature[], t: TFunction): ReactElement => {
  return (
    <>
      {modalData.map(({ key, value }) => {
        const reactKey = `${key}-${value}-${Math.random()}`;
        let data: RenderSignature = <SectionField label={key as string}>{value}</SectionField>;

        if (key === 'pinCodes') {
          data = renderPincodes(value as PinCodes, t);
        }

        if (key === 'rooms') {
          data = renderAssignedRoomData(value);
        }

        return <Fragment key={reactKey}>{data}</Fragment>;
      })}
    </>
  );
};

const formatAdditionalData = (additionalData: any): IndexSignature[] => {
  const formatted: IndexSignature[] = [];

  if (!additionalData) {
    return formatted;
  }

  Object.entries(additionalData).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (ADDITIONAL_FIELDS[key]) {
      formatted.push({
        key: ADDITIONAL_FIELDS[key],
        value: value as string,
      });
    } else {
      // convert camelCase to sentence case. e.g. nextReservationId -> Next reservation id
      let formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
      formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
      formatted.push({
        key: formattedKey,
        value: value as string,
      });
    }
  });

  return formatted;
};

interface LogProps {
  eventsLog: EventLog[];
  showMore: boolean;
  timezone: string;
}

const Logs: React.FC<LogProps> = ({ eventsLog, showMore, timezone }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([] as IndexSignature[]);

  const showAdditionalDetails = (data: IndexSignature[]): void => {
    setModalOpen(true);
    setModalData(data);
  };

  let additionalDataModal = null;
  if (modalData.length) {
    additionalDataModal = (
      <ConfirmDialog
        confirmTitle="Event Details"
        confirmText={renderAdditionalData(modalData, t)}
        open={modalOpen}
        onClose={(): void => setModalOpen(false)}
        hasCancel={false}
        confirmButtonText={t('general.ok')}
      />
    );
  }

  let logs: EventLog[] = eventsLog.slice(0, 5);
  if (showMore) {
    logs = eventsLog;
  }

  return (
    <>
      <Stepper activeStep={eventsLog.length - 1} orientation="vertical">
        {logs.map(({ event, message, timestamp: newtimeStamp, timeStamp: oldtimeStamp, additionalData, logLevel }) => {
          const timestamp = newtimeStamp || oldtimeStamp; // support old timestamps
          const zonedTimestamp = timestamp && utcToZonedTime(timestamp, timezone)
          const formattedTimestamp =
            zonedTimestamp &&
            formatDistance(zonedTimestamp, new Date(), {
              addSuffix: true,
            }).replace('about', '');


          const formattedData = formatAdditionalData(additionalData);
          const isErrorStep = logLevel === LogLevel.Error;

          return (
            <Step key={`${event}-${timestamp}`} completed active={false}>
              <StepLabel
                StepIconProps={{
                  classes: { root: isErrorStep ? classes.stepCompletedIcon : '' },
                }}
              >
                {isErrorStep && <ErrorIcon className={classes.errorIcon} fontSize="default" />}
                <strong>{`${message} `}</strong>
                {timestamp && <strong>{`${formattedTimestamp} on `}</strong>}
                {zonedTimestamp && formatReadTimestamp(zonedTimestamp, 'dd.mm.yyyy HH:MM:ss')}
                {!!formattedData.length && (
                  <VisibilityIcon
                    fontSize="small"
                    onClick={(): void => showAdditionalDetails(formattedData)}
                    className={classes.visibilityIcon}
                  />
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {additionalDataModal}
    </>
  );
};

export default Logs;
