import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import Loading from './Loading';

export interface ConfirmDialogProps {
  confirmTitle: string;
  confirmText: string | ReactElement;
  open: boolean;
  loading?: boolean;
  onClose: (accepted: boolean) => void | Promise<void>;
  hasCancel?: boolean;
  confirmButtonText?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  const { t } = useTranslation();
  const { onClose, open, confirmText, confirmTitle, loading, hasCancel = true, confirmButtonText, ...other } = props;

  const handleCancel = (): void => {
    onClose(false);
  };

  const handleOk = (): void => {
    onClose(true);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      fullWidth
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{confirmTitle}</DialogTitle>
      <DialogContent dividers>{loading ? <Loading /> : confirmText}</DialogContent>
      <DialogActions>
        {hasCancel && (
          <Button autoFocus onClick={handleCancel} color="secondary" disabled={loading}>
            {t('general.cancel')}
          </Button>
        )}
        <Button onClick={handleOk} variant="contained" color="primary" disabled={loading}>
          {confirmButtonText || t('general.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
