import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, createStyles, Grid, makeStyles } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const Login: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loginWithPopup } = useAuth0();

  return (
    // The login needs to be triggered manually using popup method as google login doesnot work inside iframe
    <Grid container className={classes.container}>
      <Button size="medium" variant="contained" color="primary" onClick={loginWithPopup}>
        {t('general.login')}
      </Button>
    </Grid>
  );
};

export default Login;
