import React from 'react';
import { Box, createStyles, Grid, GridItemsAlignment, makeStyles, Theme, Typography } from '@material-ui/core';

interface SectionFieldProps {
  label: string;
  complement?: string;
  align?: GridItemsAlignment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    sectionLabel: {
      marginRight: 5,
    },
  })
);

const SectionField: React.FC<SectionFieldProps> = ({ label, complement, align, children }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.sectionField} container justify="flex-start" alignItems={align || 'center'}>
      <Box className={classes.sectionLabel}>
        <Typography>
          <span>{complement}</span>
        </Typography>
        <Typography>
          <strong>{`${label}:`}</strong>
        </Typography>
      </Box>
      {children}
    </Grid>
  );
};

export default SectionField;
