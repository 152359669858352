import React from 'react';

import SectionField from './SectionField';
import { Button } from '@material-ui/core';

interface PinFieldWithButtonProps {
  code?: string;
  label: string;
  showButton?: boolean;
  buttonLabel?: string;
  onClick: (code: string) => void;
}

const toggleButtonOrCode = (props: PinFieldWithButtonProps): string | JSX.Element => {
  const { showButton, onClick, code, buttonLabel } = props;
  if (showButton) {
    return (<>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => onClick(code!)}
      >
        {buttonLabel}
      </Button>
    </>)
  }

  return code!;
}

const PinFieldWithButton: React.FC<PinFieldWithButtonProps> = (props) => {
  const { code, label } = props;
  return (
    <>{code && <SectionField label={label}>{toggleButtonOrCode(props)}</SectionField>}</>
  )
};

export default PinFieldWithButton;
