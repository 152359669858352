import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createMuiTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

import { Navigation } from './components/navigation';
import { initI18 } from './util/language';
// eslint-disable-next-line import/order
import DateFnsUtils from '@date-io/date-fns';

initI18();

const queryClient = new QueryClient();

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
  },
  typography: {
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: `${blue[500]} !important`,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: `${blue[800]} !important`,
        },
      },
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="app">
          <ThemeProvider theme={theme}>
            <Navigation />
          </ThemeProvider>
        </div>
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  );
};

export default App;
