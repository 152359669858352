import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

import { updateLanguage, useMutateApiData } from '../../util/api';
import { ErrorMessage } from '../common';
import { SelectField } from '../common/section';

interface PaymentDetailsProps {
  id: string;
  language: string;
}

const LANGUAGES = ['EN', 'DE', 'ES', 'CZ', 'IT', 'FR', 'NL'];

const LanguageSwitch: React.FC<PaymentDetailsProps> = ({ id, language: defaultLanguage = '' }) => {
  const { error, isLoading, mutateAsync: updateLanguageRequest } = useMutateApiData(updateLanguage);
  const [language, setLanguage] = React.useState<string>(defaultLanguage?.toUpperCase());

  const { t } = useTranslation();

  const onChange = (_: string, preferredLang: string): void => {
    setLanguage(preferredLang);
  };

  const onSubmit = async (): Promise<void> => {
    await updateLanguageRequest([id, { language: language.toLowerCase() }]);
  };

  return (
    <>
      <SelectField
        field="preferredLanguage"
        label={t('language.preferredLanguage')}
        onChange={onChange}
        value={language}
        options={LANGUAGES.map(lang => ({ value: lang, label: lang }))}
        inputLabel={t('sections.language').toLowerCase()}
      />
      <Box flexDirection="row">
        <Button
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          disabled={isLoading || language.toLowerCase() === defaultLanguage}
        >
          {t('general.save')}
        </Button>
        {isLoading && <Typography>{t('general.loading')}</Typography>}
      </Box>
      {error && <ErrorMessage message={t('general.error')} />}
    </>
  );
};

export default LanguageSwitch;
