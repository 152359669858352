import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { GetProofOfIdResponse, QueryStrings, ReservationDetails } from '../../../types';
import { getProofOfId, useQueryApiData } from '../../../util/api';
import TextInputField from '../../common/section/TextInputField';
import S3File from './S3File';
import { getGuestDocumentStatus } from './util';

type Props = Pick<ReservationDetails, 'reservation'>;

const useStyles = makeStyles({
  proofOfIdWrapper: {
    display: 'grid',
    gap: '20px',
  },
  fileGrid: {
    marginTop: '20px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
  },
  dialogTrigger: {
    border: 'none',
    padding: '0',
    background: 'transparent',
    cursor: 'pointer',
  },
  guestList: {
    display: 'grid',
    gap: '10px',
  },
  guest: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

export const ProofOfId = ({ reservation }: Props) => {
  const classes = useStyles();
  const reservationId = reservation.id;
  const { isLoading, isSuccess, data } = useQueryApiData<GetProofOfIdResponse>([reservationId, QueryStrings.PROOF_OF_ID], getProofOfId);
  const { urls = {} } = reservation;
  const uploadPageUrl = useMemo(() => {
    if(!urls.guestRegistration) {
      return undefined;
    }
    const url = new URL(urls.guestRegistration);
    url.pathname = 'id-upload';
    return url.href;
  }, [urls]);
  const { t } = useTranslation();

  const [openedFile, setOpenedFile] = useState<string | undefined>(undefined);
  const closeDialog = () => setOpenedFile(undefined);

  return (
    <div className={classes.proofOfIdWrapper}>
      {isLoading && <CircularProgress />}

      {isSuccess && (
        <>
          {data?.registrationStatus && data?.registrationStatus === 'complete' ? (
            <div className={classes.guestList}>
              {data?.guests.map((guest, i) => {
                return (
                  <div key={`guest-${i}`} className={classes.guest}>
                    <strong>
                      {guest.firstName} {guest.secondSurname ?? guest.lastName}
                    </strong>
                    <span>{getGuestDocumentStatus(guest)}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <>DCi not complete</>
          )}

          <div>
            <strong>Additional ID uploads</strong>
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            {uploadPageUrl && <TextInputField label="Link to Upload page" value={uploadPageUrl} onChange={() => {}} />}

            <div className={classes.fileGrid}>
              {data?.reservationDocuments?.map(document => (
                <S3File
                  key={document.downloadUrl}
                  downloadUrl={document.downloadUrl}
                  isPreview
                  onClick={() => setOpenedFile(document.downloadUrl)}
                />
              ))}
            </div>

            {openedFile && (
              <Dialog open={!!openedFile} onClose={closeDialog}>
                <S3File downloadUrl={openedFile} />

                <DialogActions>
                  <Button onClick={closeDialog} variant="contained" color="primary">
                    {t('general.ok')}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </>
      )}
    </div>
  );
};
