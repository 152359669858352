import { format, parse } from 'date-fns';
import dateformat from 'dateformat';

import { HOURS } from './util/time';

export const formatReadDate = (d: Date): string => dateformat(d, 'dd.mm.yyyy');
export const formatReadTimestamp = (d: Date, formatStr = 'dd.mm.yyyy hh:mm'): string => dateformat(d, formatStr);
export const formatDate = (d: Date): string => dateformat(d, 'yyyy-mm-dd');

export const formatTimeStamp = (d: string): string => format(new Date(d), 'dd.MM.yyyy hh:mm a');

export const getDateTime = (date: string | undefined): { date: Date; time: string } => {
  const newDate = date ? new Date(date) : new Date();
  return {
    date: newDate,
    time: date ? format(newDate, 'HH:mm') : HOURS[HOURS.length - 1],
  };
};

export const getTimeStamp = (d: Date, time: string): string => {
  const timeDate = parse(time, 'HH:mm', d);
  return timeDate.toISOString();
};
