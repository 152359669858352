import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useContentLang } from '../../hooks/useContentLang';
import { ContentLanguage, TranslatedString } from '../../types';
import { getDateTime, getTimeStamp } from '../../util';
import { sendBulkEmail, sendBulkSms, useMutateApiData } from '../../util/api';
import { HOURS } from '../../util/time';
import { ErrorMessage } from '../common';
import LanguageTabs from '../common/LanguageTabs';
import { SectionField, SelectField, TextAreaField, TextInputField } from '../common/section';
import HtmlInputField from '../common/section/HtmlInputField';
import BulkMessageFilter from './BulkMessageFilter';
import {
  BulkMessageEmailTypesEnum,
  BulkMessageFilters,
  MessageTypes,
  RESERVATION_STATUS_OPTIONS,
  ScheduledMessage,
  TRANSLATABLE_STRING,
} from './types';

interface BulkMessageFormProps {
  propertyId: string | null;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  type: MessageTypes;
  formData?: ScheduledMessage;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justify: 'flexStart',
    },
    cancelBtn: {
      marginRight: '8px',
    },
    timePicker: {
      width: '130px',
      marginLeft: '16px',
    },
    loading: {
      marginRight: '8px',
      marginTop: '4px',
    },
    scheduleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const BulkMessageForm: React.FC<BulkMessageFormProps> = ({ propertyId, open, onClose, onSave, type, formData }) => {
  const { t } = useTranslation();
  const langs = useContentLang();

  const { error: bulkEmailError, isLoading: bulkEmailLoading, mutateAsync: bulkEmailRequest } = useMutateApiData(
    sendBulkEmail
  );
  const { error: bulkSmsError, isLoading: bulkSmsLoading, mutateAsync: bulkSmsRequest } = useMutateApiData(sendBulkSms);
  const [filters, setFilters] = useState<BulkMessageFilters>({
    reservationStatus: formData?.reservationStatus ?? RESERVATION_STATUS_OPTIONS[0],
    reservationDate: formData?.reservationDate ?? new Date().toISOString(),
    reservationIds: formData?.reservationIds?.join(','),
    emailType: formData?.emailType ?? BulkMessageEmailTypesEnum.individualEmail,
  });
  const { date: scheduledDate, time: scheduledtime } = getDateTime(formData?.scheduledAt);

  const [time, setTime] = useState<string>(scheduledtime);
  const [date, setDate] = useState<Date>(scheduledDate);
  const [subject, setSubject] = useState<TranslatedString>(formData?.subject ?? TRANSLATABLE_STRING);
  const [content, setContent] = useState<TranslatedString>(formData?.content ?? TRANSLATABLE_STRING);
  const [isScheduled, setIsScheduled] = useState<boolean>(formData?.isScheduled ?? false);
  const [currentLang, setCurrentLang] = React.useState(ContentLanguage.EN);

  const classes = useStyles();

  const handleFilterChange = (changedFilters: BulkMessageFilters) => {
    setFilters(changedFilters);
  };

  const onLangChange = (lang: ContentLanguage) => {
    setCurrentLang(lang);
  };

  const onSubmit = async (): Promise<void> => {
    const scheduledAt = getTimeStamp(date, time);
    const basePayload = {
      id: formData?.id,
      propertyId,
      content,
      emailType: filters.emailType,
      lang: filters.lang?.toLowerCase(),
      reservationStatus: filters.reservationStatus,
      reservationDate: filters.reservationDate,
      scheduledAt,
      isScheduled,
      reservationIds: filters.reservationIds,
    };

    if (type === MessageTypes.EMAIL) {
      await bulkEmailRequest([
        {
          ...basePayload,
          subject,
        },
      ]);
    } else {
      await bulkSmsRequest([basePayload]);
    }

    onSave();
    onClose();
  };

  const isDisabled =
    type === MessageTypes.EMAIL
      ? !((subject.en && content.en && propertyId) || filters.emailType !== BulkMessageEmailTypesEnum.individualEmail)
      : !(content.en && propertyId);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="bulk-message-schedule-dialog"
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <DialogTitle id="simple-dialog-title">
        {type === MessageTypes.EMAIL ? t('bulkMessaging.emailPopupHeading') : t('bulkMessaging.smsPopupHeading')}
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContentContainer}>
        <BulkMessageFilter messageType={type} filters={filters} onChange={handleFilterChange} />
        <SectionField label={t('bulkMessaging.scheduleMessage')}>
          <Checkbox
            checked={isScheduled}
            onChange={e => {
              setIsScheduled(e.target.checked);
            }}
          />
        </SectionField>
        {isScheduled && (
          <div className={classes.scheduleContainer}>
            <DatePicker
              label={t('bulkMessaging.selectDate')}
              name="start"
              value={date}
              onChange={e => {
                if (e) {
                  setDate(e);
                }
              }}
              size="small"
              disablePast
            />
            <div className={classes.timePicker}>
              <SelectField
                field="time"
                label={t('bulkMessaging.selectTime')}
                inputLabel={t('bulkMessaging.timeLabel')}
                onChange={(_field, value) => {
                  setTime(value);
                }}
                value={time}
                options={HOURS.map(value => ({ value, label: value }))}
                align="flex-end"
              />
            </div>
          </div>
        )}
        {(type === MessageTypes.SMS ||
          (type === MessageTypes.EMAIL && filters.emailType === BulkMessageEmailTypesEnum.individualEmail)) && (
          <>
            <LanguageTabs langs={langs} onLangChange={onLangChange} />
            {type === MessageTypes.EMAIL && (
              <SectionField label={t('bulkMessaging.emailSubject')}>
                <TextInputField
                  onChange={(_field, value) => {
                    const newSubject = { ...subject };
                    newSubject[currentLang] = value;
                    setSubject(newSubject);
                  }}
                  value={subject[currentLang]}
                />
              </SectionField>
            )}

            {type === MessageTypes.EMAIL ? (
              <HtmlInputField
                label={t('bulkMessaging.emailContent')}
                onChange={value => {
                  const newContent = { ...content };
                  newContent[currentLang] = value;
                  setContent(newContent);
                }}
                value={content[currentLang]}
              />
            ) : (
              <TextAreaField
                label={t('bulkMessaging.smsContent')}
                onChange={(_key, value) => {
                  const newContent = { ...content };
                  newContent[currentLang] = value;
                  setContent(newContent);
                }}
                value={content[currentLang]}
              />
            )}
          </>
        )}

        <Box display="flex" justifyContent="end">
          {(bulkEmailLoading || bulkSmsLoading) && (
            <Typography className={classes.loading}>{t('general.loading')}</Typography>
          )}
          <Button variant="outlined" color="default" onClick={onClose} className={classes.cancelBtn}>
            {t('general.cancel')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onSubmit}
            disabled={bulkEmailLoading || bulkSmsLoading || isDisabled}
          >
            {t('general.confirm')}
          </Button>
        </Box>
        {(bulkEmailError || bulkSmsError) && <ErrorMessage message={t('general.error')} />}
      </DialogContent>
    </Dialog>
  );
};

export default BulkMessageForm;
