import React from 'react';
import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

interface UploadFieldProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label: string;
  id: string;
  multiple?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  const uploadBtnStyles = {
    color: theme.palette.primary.light,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.light}`,
    padding: '3px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  };
  return createStyles({
    uploadInput: { visibility: 'hidden', width: 0, height: 0 },
    uploadInputBtn: uploadBtnStyles,
    uploadInputBtnDisabled: {
      ...uploadBtnStyles,
      color: theme.palette.grey[400],
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    labelStyle: {
      display: 'flex',
    },
    progressBar: {
      marginRight: '10px',
      height: '25px !important',
      width: '25px !important',
      color: theme.palette.grey[400],
    },
  });
});

const UploadField: React.FC<UploadFieldProps> = ({ onChange, label, id, multiple, disabled, loading }) => {
  const classes = useStyles();
  const disableUpload = disabled || loading;
  return (
    <label htmlFor={id} className={classes.labelStyle}>
      <input
        className={classes.uploadInput}
        disabled={disableUpload}
        id={id}
        multiple={multiple}
        type="file"
        onChange={onChange}
      />
      <div className={disableUpload ? classes.uploadInputBtnDisabled : classes.uploadInputBtn}>
        {loading ? (
          <CircularProgress className={classes.progressBar} />
        ) : (
          <CloudUpload style={{ paddingRight: '10px' }} />
        )}{' '}
        {label}
      </div>
    </label>
  );
};

export default UploadField;
