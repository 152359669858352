import { useTranslation } from 'react-i18next';

import { ContentLanguage, ContentLanguageConfig, TranslatedString } from '../types';

export const defaultLang = ContentLanguage.EN;

export const defaultLangString: TranslatedString = {
  [ContentLanguage.EN]: '',
  [ContentLanguage.DE]: '',
  [ContentLanguage.CZ]: '',
  [ContentLanguage.ES]: '',
  [ContentLanguage.IT]: '',
  [ContentLanguage.FR]: '',
  [ContentLanguage.NL]: '',
};

export const useContentLang = (): ContentLanguageConfig[] => {
  const { t } = useTranslation();

  return [
    { label: t('language.english'), code: ContentLanguage.EN },
    { label: t('language.german'), code: ContentLanguage.DE },
    { label: t('language.spanish'), code: ContentLanguage.ES },
    { label: t('language.italian'), code: ContentLanguage.IT },
  ];
};
