import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import SectionField from './SectionField';

interface CountFieldProps {
  onChange: (x: string, y: number) => void;
  label: string;
  value?: number;
  maxAmount: number;
}

const useStyles = makeStyles({
  form: {
    marginLeft: 20,
    minWidth: 80,
  },
});

const CountField: React.FC<CountFieldProps> = ({ onChange, label, value, maxAmount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onChange(label, event.target.value as number);
  };

  return (
    <SectionField label={label}>
      <FormControl className={classes.form}>
        <InputLabel>{t('general.selection')}</InputLabel>
        <Select id="demo-simple-select" value={value} onChange={handleChange}>
          {[...Array(maxAmount)].map((x, i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SectionField>
  );
};

export default CountField;
