import React, { Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import environmentConfig from '../../config';
import { EnumRecordArchivedState, SelectedMisconductRecords, SuspiciousGuestRecord } from '../../types';
import { WatchListTableHeader } from './WatchListTableHeader';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    WatchListToolBar: {
      width: 300,
    },
    WatchListToolBarTitle: {
      flex: '1 1 100%',
    },
    WatchListToolBarHidden: {
      visibility: 'hidden',
    },
  })
);

const selectFields = (record: SuspiciousGuestRecord) => {
  return {
    misconductTypes: record.misconductTypes.join(', '),
    misconductDegree: record.misconductDegree,
    firstName: record.firstName,
    lastName: record.lastName,
    phoneNumber: record.phoneNumber,
    email: record.email,
    street: record.street,
    postalCode: record.postalCode,
    city: record.city,
    country: record.country,
  };
};

const renderCells = (record: SuspiciousGuestRecord) => {
  const fields = selectFields(record);
  return Object.values(fields).map((field, index) => {
    return (
      <TableCell key={`${record.recordId}-${index}`} align="center">
        {field}
      </TableCell>
    );
  });
};

interface WatchListRecordsProps {
  records: SuspiciousGuestRecord[];
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, field: keyof SelectedMisconductRecords) => void;
  handleCheckboxClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    recordId: string,
    field: keyof SelectedMisconductRecords
  ) => void;
  selectedRecords: string[];
  recordType: EnumRecordArchivedState;
}

const renderReservationLinks = (record: SuspiciousGuestRecord) => {
  const { reservations } = record;
  return reservations.map(({ id, propertyId }, index) => {
    const detailsPageId = environmentConfig.isProd
      ? 'COSIGROUPLIVE-RESERVATIONDETAILS'
      : 'INTEGRATION-SWAGGER-UI-RESERVATIONDETAILS';

    const delimiter = reservations.length - 1 !== index ? ' - ' : '';
    return (
      <Fragment key={`${id}-${index}`}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.apaleo.com/${propertyId}/reservations/${id}/${detailsPageId}`}
        >
          {id}
        </a>
        {delimiter}
      </Fragment>
    );
  });
};

export const WatchListRecords: React.FC<WatchListRecordsProps> = ({
  records,
  handleSelectAllClick,
  handleCheckboxClick,
  selectedRecords,
  recordType,
}) => {
  const classes = useStyles();
  const isSelected = (name: string) => selectedRecords.indexOf(name) !== -1;

  return (
    <TableContainer>
      <Table className={classes.root} aria-labelledby="watchListTable" size={'medium'}>
        <WatchListTableHeader
          selections={selectedRecords.length}
          onSelectAllClick={event => handleSelectAllClick(event, recordType)}
          rowCount={records.length}
        />
        <TableBody>
          {records.map((record, index) => {
            const isItemSelected = isSelected(record.recordId);
            const labelId = `suspicious-guest-record-${index}`;

            return (
              <TableRow
                hover
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={record.recordId}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={event => handleCheckboxClick(event, record.recordId, recordType)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none">
                  {renderReservationLinks(record)}
                </TableCell>
                {renderCells(record)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
