import React from 'react';
import { Typography } from '@material-ui/core';

import { Logout } from '../auth';

interface ErrorMessageProps {
  message: string;
  showLogout?: boolean;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, showLogout, className }) => {
  return (
    <>
      <Typography variant="subtitle1" color="error" className={className}>
        {message}
      </Typography>
      {showLogout && <Logout />}
    </>
  );
};

export default ErrorMessage;
