import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { SimpleCard } from '../common';
import BulkMessageForm from './BulkMessageForm';
import { BulkMessageScheduleTable } from './BulkMessageScheduleTable';
import { MessageTypes, ScheduledMessage } from './types';

interface BulkMessageSectionProps {
  propertyId: string | null;
  scheduledMessages: ScheduledMessage[];
  fetchBulkMessageSchedules: (...apiArgs: any[]) => Promise<any>;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
    emailBtnGrp: {
      position: 'absolute',
      right: '0px',
      top: '-45px',
    },
    emailBtn: {
      marginRight: '16px',
    },
  })
);

export const BulkMessageSection: React.FC<BulkMessageSectionProps> = ({
  propertyId,
  scheduledMessages,
  fetchBulkMessageSchedules,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [selectedScheduleMessage, setSelectedScheduleMessage] = useState<ScheduledMessage | undefined>();
  const [modalContent, setModalContent] = useState<MessageTypes>();

  const handleBulkMessagePopup = (messageType: MessageTypes) => {
    if (messageType === MessageTypes.EMAIL) {
      setModalContent(MessageTypes.EMAIL);
    } else {
      setModalContent(MessageTypes.SMS);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {modalContent && openModal && (
        <BulkMessageForm
          propertyId={propertyId}
          onClose={handleCloseModal}
          open={openModal}
          onSave={() => fetchBulkMessageSchedules(propertyId)}
          type={modalContent}
          formData={selectedScheduleMessage}
        />
      )}

      <Grid style={{ paddingBottom: '65px' }} container direction="row" justify="space-around">
        <Grid container direction="column" item sm={12}>
          <SimpleCard title={t('sections.bulkMessageSchedules')} maxWidth="100%">
            <div className={classes.container}>
              <div className={classes.emailBtnGrp}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.emailBtn}
                  onClick={() => {
                    setSelectedScheduleMessage(undefined);
                    handleBulkMessagePopup(MessageTypes.EMAIL);
                  }}
                >
                  Schedule Bulk Email
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedScheduleMessage(undefined);
                    handleBulkMessagePopup(MessageTypes.SMS);
                  }}
                >
                  Schedule Bulk Sms
                </Button>
              </div>
              <BulkMessageScheduleTable
                propertyId={propertyId}
                scheduledMessages={scheduledMessages}
                fetchBulkMessageSchedules={fetchBulkMessageSchedules}
                getEditableSchedule={(data: ScheduledMessage) => {
                  setSelectedScheduleMessage(data);
                  handleBulkMessagePopup(data.type);
                }}
              />
            </div>
          </SimpleCard>
        </Grid>
      </Grid>
    </>
  );
};
