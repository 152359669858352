/* eslint-disable no-use-before-define */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    marginBottom: '20px',
  },
}));

interface Option {
  label: string;
  value: string;
}

interface MultiSelectFieldProps {
  onChange: (x: React.ChangeEvent<unknown>, y: Option[]) => void;
  placeholder?: string;
  defaultValue?: Option[];
  options: (string | Option)[];
  label: string;
  // allows manual input of values not present in default Options
  freeSolo?: boolean;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = props => {
  const { label, placeholder, defaultValue, options, onChange, freeSolo } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        freeSolo={freeSolo}
        autoSelect
        options={options}
        getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
        defaultValue={defaultValue || []}
        renderInput={params => <TextField {...params} variant="standard" label={label} placeholder={placeholder} />}
        onChange={(event, elements) => {
          const computedElements: Option[] = elements.map(el => {
            if (typeof el === 'string') {
              return {
                label: el,
                value: el,
              };
            }
            return el;
          });
          onChange(event, computedElements);
        }}
      />
    </div>
  );
};

export default MultiSelectField;
