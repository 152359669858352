import { Typography } from '@material-ui/core';
import React from 'react';
import { HskDelayResponse } from '../../types';
import { formatTimeStamp } from '../../util';
import { usePincodesStyles } from './PinCodes';

type Props = { hskDelay: HskDelayResponse };

const HskDelay: React.FC<Props> = ({ hskDelay }) => {
    const classes = usePincodesStyles();
    
    return (
        <div className={classes.container}>
            <Typography component="span">Housekeeping delay set for <strong>{formatTimeStamp(new Date(hskDelay.delayUntil).toISOString())}</strong></Typography>
        </div>
    );
}

export default HskDelay;
