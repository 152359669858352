import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import fileDownload from 'js-file-download';

import { Document, EnumCovDocumentStatus, Guest } from '../../../types';
import { changeCovidDocumentsStatus, saveCovidDocumentsApi, useMutateApiData } from '../../../util/api';
import { useCovidDocsUploader } from '../../../util/upload';
import { ConfirmDialog, ErrorMessage } from '../../common';
import { UploadField } from '../../common/section';
import { SplitButton } from '../../common/SplitButton';

interface GuestDocumentsProps {
  reservationId: string;
  covDocumentStatus: EnumCovDocumentStatus;
  idCheckNeeded: boolean;
  refetchReservation: () => Promise<any>;
  guests: Guest[];
}

enum RequestType {
  Approve = 'approve',
  Reject = 'reject',
}

const useStyles = makeStyles((theme: Theme) => {
  const buttonStyle = {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  };
  return createStyles({
    statusBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2.5),
    },
    statusText: {
      fontWeight: 600,
    },
    button: {
      ...buttonStyle,
    },
    approveBtn: {
      ...buttonStyle,
      color: '#35bf35',
      borderColor: '#35bf35',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },
    guestDocuments: {
      marginTop: theme.spacing(2),
    },
    guestName: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    documentButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    documentDownloadButton: {
      flexGrow: 1,
    },
    errorMessage: {
      marginTop: theme.spacing(1),
    },
    notAllDocumentsProvided: {
      marginTop: theme.spacing(1),
    },
    noIdCheckNeeded: {
      marginTop: theme.spacing(3),
    },
  });
});

const GuestDocuments: React.FC<GuestDocumentsProps> = ({
  reservationId,
  covDocumentStatus,
  idCheckNeeded,
  guests,
  refetchReservation,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { error, isLoading, mutateAsync: updateCovidDocumentsStatus } = useMutateApiData(changeCovidDocumentsStatus);
  const { error: saveDocumentError, mutateAsync: saveCovidDocuments } = useMutateApiData(saveCovidDocumentsApi);

  const { error: covidUploadError, mutateAsync: uploadCovidDocs } = useCovidDocsUploader();

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [currentRequest, setCurrentRequest] = useState<RequestType>(RequestType.Approve);
  const [modalConfirmLoading, setModalConfirmLoading] = useState<boolean>(false);
  const [uploadLoader, setUploadLoader] = useState<Guest['id'] | false>(false);

  const allGuestsHaveDocuments =
    !!guests?.length && guests?.every(({ downloadUrls }) => !!downloadUrls?.covidDocuments.length);
  const canChangeCovDocuments = covDocumentStatus !== EnumCovDocumentStatus.APPROVED;
  const shouldShowCovStatusToggle =
    allGuestsHaveDocuments &&
    (!covDocumentStatus ||
      covDocumentStatus === EnumCovDocumentStatus.NEEDS_CHECKING ||
      covDocumentStatus === EnumCovDocumentStatus.REJECTED);

  const confirmTitle = {
    [RequestType.Approve]: t('guestDocuments.approveTitle'),
    [RequestType.Reject]: t('guestDocuments.rejectTitle'),
  };
  const confirmText = {
    [RequestType.Approve]: t('guestDocuments.approveText'),
    [RequestType.Reject]: t('guestDocuments.rejectText'),
  };

  const onSubmit = async (status: EnumCovDocumentStatus) => {
    setModalConfirmLoading(true);
    await updateCovidDocumentsStatus([
      reservationId,
      {
        status,
      },
    ]);
    await refetchReservation();
    setModalConfirmLoading(false);
  };

  const onModalClose = async (accepted: boolean): Promise<void> => {
    if (!accepted) {
      setConfirmModalOpen(false);
      return;
    }
    switch (currentRequest) {
      case RequestType.Approve:
        await onSubmit(EnumCovDocumentStatus.APPROVED);
        break;
      case RequestType.Reject:
        await onSubmit(EnumCovDocumentStatus.REJECTED);
        break;
      default:
        return;
    }
    setConfirmModalOpen(false);
  };

  const getCovDocumentStatusText = () => {
    if (!covDocumentStatus) {
      return t('guestDocuments.needsChecking');
    }

    switch (covDocumentStatus) {
      case EnumCovDocumentStatus.APPROVED:
        return t('guestDocuments.approved');
      case EnumCovDocumentStatus.REJECTED:
        return t('guestDocuments.rejected');
      default:
        return t('guestDocuments.needsChecking');
    }
  };

  const openModal = (reqType: RequestType) => {
    setCurrentRequest(reqType);
    setConfirmModalOpen(true);
  };

  const onFileSelect = (guestId: string) => async (e: any) => {
    const guest = guests.find(({ id }) => id === guestId)!;
    setUploadLoader(guestId);
    const fileDetails = await uploadCovidDocs([reservationId, e.target.files]);
    const formattedDocuments =
      guest.downloadUrls?.covidDocuments.map((document: Document) => ({
        name: document.name,
        type: document.type,
      })) ?? [];
    const allCovidDocuments = [...formattedDocuments, ...fileDetails];
    await saveCovidDocuments([reservationId, guestId, allCovidDocuments]);
    await refetchReservation();
    setUploadLoader(false);
  };

  const onDocumentDownload = async (documents: Document[]): Promise<void> => {
    for (const { url, name: fileName, type: fileType } of documents) {
      const data = await fetch(url, {
        method: 'GET',
        headers: { Accept: fileType, 'Content-Type': 'application/json' },
      });
      const response = await data.blob();
      fileDownload(response, fileName);
    }
  };

  const getDownloadOptions = useCallback(
    (guest: Guest) => {
      if (!guest.downloadUrls?.idDocuments) {
        return [
          {
            label: t('guestDocuments.downloadCovidDocuments'),
            id: 'covidDocuments',
          },
        ] as const;
      }

      return [
        {
          label: t('guestDocuments.downloadDocuments'),
          id: 'allDocuments',
        },
        {
          label: t('guestDocuments.downloadCovidDocuments'),
          id: 'covidDocuments',
        },
        {
          label: t('guestDocuments.downloadIdDocuments'),
          id: 'idDocuments',
        },
      ] as const;
    },
    [t]
  );
  type DownloadOption = ReturnType<typeof getDownloadOptions>[number];

  // eslint-disable-next-line consistent-return
  const handleDownloadDocuments = (id: DownloadOption['id'], downloadUrls: Guest['downloadUrls']) => {
    switch (id) {
      case 'allDocuments':
        return onDocumentDownload([...downloadUrls.covidDocuments, ...(downloadUrls.idDocuments ?? [])]);
      case 'covidDocuments':
        return onDocumentDownload(downloadUrls.covidDocuments);
      case 'idDocuments':
        return onDocumentDownload(downloadUrls.idDocuments ?? []);
      default:
        break;
    }
  };

  if (!allGuestsHaveDocuments) {
    return <div className={classes.notAllDocumentsProvided}>{t('guestDocuments.notAllDocumentsProvided')}</div>;
  }

  return (
    <>
      <>
        <div className={classes.statusBox}>
          <span>{t('guestDocuments.title')}</span>
          <span className={classes.statusText}>{getCovDocumentStatusText()}</span>
        </div>

        {shouldShowCovStatusToggle && (
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="primary"
              disabled={isLoading}
              className={classes.approveBtn}
              onClick={() => openModal(RequestType.Approve)}
            >
              {t('guestDocuments.approve')}
            </Button>
            <Button
              variant="outlined"
              disabled={isLoading}
              color="secondary"
              className={classes.button}
              onClick={() => openModal(RequestType.Reject)}
            >
              {t('guestDocuments.reject')}
            </Button>
          </div>
        )}
      </>

      <div>
        {guests.map(guest => {
          const { id, firstName, lastName, downloadUrls } = guest;

          return (
            <div className={classes.guestDocuments} key={`upload-covid-docs-guest-${id}`}>
              <div className={classes.guestName}>
                {firstName} {lastName}
              </div>

              <div className={classes.documentButtonsContainer}>
                <SplitButton
                  className={classes.documentDownloadButton}
                  variant="outlined"
                  color="primary"
                  options={getDownloadOptions(guest)}
                  onClick={optionId => handleDownloadDocuments(optionId, downloadUrls)}
                />

                {canChangeCovDocuments && (
                  <UploadField
                    loading={uploadLoader === id}
                    id="upload-covid-docs"
                    onChange={onFileSelect(id)}
                    multiple={true}
                    label={t('guestDocuments.uploadBtnText')}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      {!idCheckNeeded && <div className={classes.noIdCheckNeeded}>{t('guestDocuments.noIdCheckNeeded')}</div>}

      <Box flexDirection="row">{isLoading && <Typography>{t('general.loading')}</Typography>}</Box>
      <ConfirmDialog
        open={confirmModalOpen}
        confirmTitle={confirmTitle[currentRequest]}
        confirmText={confirmText[currentRequest]}
        onClose={onModalClose}
        loading={modalConfirmLoading}
      />
      {(error || saveDocumentError || covidUploadError) && (
        <ErrorMessage className={classes.errorMessage} message={t('general.error')} />
      )}
    </>
  );
};

export default GuestDocuments;
