import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

import { Misconduct, MisconductDegree } from '../../types';
import { updateMisconductDetails, useMutateApiData } from '../../util/api';
import { ErrorMessage } from '../common';
import { MultiSelectField, SelectField, TextAreaField } from '../common/section';

interface MisconductReportProps {
  id: string;
  misconduct?: Misconduct;
}

const misconductDegreeOptions = [
  MisconductDegree.NONE.toUpperCase(),
  MisconductDegree.LOW.toUpperCase(),
  MisconductDegree.HIGH.toUpperCase(),
];

const MISCONDUCT_TYPE = [
  {
    label: 'Tardy Check-out',
    value: 'Tardy Check-out',
  },
  {
    label: 'Fraud',
    value: 'Fraud',
  },
  {
    label: 'Commercial Activities',
    value: 'Commercial Activities',
  },
  {
    label: 'Smoking',
    value: 'Smoking',
  },
  {
    label: 'Drug abuse',
    value: 'Drug abuse',
  },
  {
    label: 'Extremely Dirty',
    value: 'Extremely Dirty',
  },
  {
    label: 'Partying',
    value: 'Partying',
  },
  {
    label: 'Property damage',
    value: 'Property damage',
  },
  {
    label: 'Theft',
    value: 'Theft',
  },
  {
    label: 'Fake ID',
    value: 'Fake id',
  },
  {
    label: 'Screaming & Shouting',
    value: 'Screaming & Shouting',
  },
  {
    label: 'Shower pooper',
    value: 'Shower pooper',
  },
  {
    label: 'Missing or Delayed Payments',
    value: 'Missing or Delayed Payments',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

const MisconductReport: React.FC<MisconductReportProps> = ({ id, misconduct }) => {
  const defaultMisconductOptions = MISCONDUCT_TYPE.filter(field => {
    return (misconduct?.types || []).includes(field.value);
  });
  const defaultMisconduct: Misconduct = {
    types: misconduct?.types || [],
    degree: misconduct?.degree ? misconduct.degree.toUpperCase() : MisconductDegree.NONE.toUpperCase(),
    additionalComment: misconduct?.additionalComment || '',
  };

  const { t } = useTranslation();
  const { error, isLoading, mutateAsync: updateMisconductDetailsRequest } = useMutateApiData(updateMisconductDetails);
  const [misconductData, setMisconductData] = useState<Misconduct>(defaultMisconduct);
  const [misconductTypes, setMisconductTypes] = useState<string[]>(defaultMisconduct.types);
  const [hasChanges, setHasChanges] = useState(false);

  const handleChange = (field: keyof Omit<Misconduct, 'types'>, value: string) => {
    setHasChanges(true);
    const newMisconductData = { ...misconductData };
    newMisconductData[field] = value;
    setMisconductData(newMisconductData);
  };

  const onSubmit = async (): Promise<void> => {
    let misconductDegree = misconductData?.degree?.toLowerCase();
    // treating empty value as none in dropdown so that high/low can be reset.
    if (misconductDegree === MisconductDegree.NONE) {
      misconductDegree = '';
    }
    await updateMisconductDetailsRequest([
      id,
      {
        ...misconductData,
        degree: misconductDegree,
        types: misconductTypes,
      },
    ]);
  };

  const isRequiredFieldsFilled = hasChanges && misconductTypes.length && misconductData.degree;
  return (
    <>
      <MultiSelectField
        label={t('misconduct.type')}
        onChange={(e, values) => {
          setMisconductTypes(values.map(el => el.value));
        }}
        options={MISCONDUCT_TYPE}
        defaultValue={defaultMisconductOptions}
      />
      <SelectField
        field="degree"
        label={t('misconduct.degree')}
        onChange={handleChange}
        value={misconductData.degree}
        options={misconductDegreeOptions.map(o => ({ value: o, label: o }))}
        inputLabel="degree"
      />
      <TextAreaField
        field="additionalComment"
        label={t('misconduct.additionalComment')}
        onChange={handleChange}
        value={misconductData.additionalComment}
        placeHolder="Optional"
      />
      <Box flexDirection="row">
        <Button variant="outlined" color="primary" onClick={onSubmit} disabled={isLoading || !isRequiredFieldsFilled}>
          {t('general.save')}
        </Button>
        {isLoading && <Typography>{t('general.loading')}</Typography>}
      </Box>
      {error && <ErrorMessage message={t('general.error')} />}
    </>
  );
};

export default MisconductReport;
